import { useEffect, useState } from "react";
//import moment from "moment";
import { dateToNumber } from "react-export-excel/dist/ExcelPlugin/utils/DataUtil";
import { ModalAplicate } from "./Components/ModalAplicate";
import { ModalAplicateDos } from "./Components/ModalAplicateDos";
import { ButtonAplicate, PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellTotal, 
         PropertyTableContainer, 
         PropertyTableHeader, 
         PropertyTableHeaderRow, 
         PropertyTableRow } from "./styles";


export const AplicateFarms = (props) => {

    const [ estado, setEstado ] = useState(false)
    const [ modal, setModal ] = useState(false);
    const [ modaldos, setModalDos ] = useState(false);
    const [ animarModal, setAnimarModal ] = useState(false);
    const [ idAplicate, setIdAplicate ] = useState(null);

    const Cargar = ()=>{
        setTimeout(()=>{                                  
             setEstado(true)                        
        }, 200)           
    }
    //cargar data
    useEffect(() => {  
        if(!estado){
            Cargar()
        }               
    },[props.receptions])

    let receptions = []
    receptions = (props.receptions.map((item)=>
        item
    ))  
    let rdts = []
    rdts = (props.rdts.map((item)=>
        item
    ))
    let sucursals = []
    sucursals = (props.sucursals.map((item)=>
        item
    ))
    //console.log({receptions: receptions})
    //console.log({rdts: rdts})
    //console.log({sucursals: sucursals})

    const handleModalAplicateFarm = (idDetail)=>{
        setModal(true)
        setIdAplicate(idDetail);
        //console.log({id: idAplicate})  
        setTimeout(() => {
          setAnimarModal(true)
        }, 300);
    }

    const handleModalAplicateFarmDos = (idDetail)=>{
        setModalDos(true)
        setIdAplicate(idDetail);
        //console.log({id: idAplicate})  
        setTimeout(() => {
          setAnimarModal(true)
        }, 300);
    }

    //1.Codigos Unicos Recepcionados y Nombre de Fincas como Index---------------------------------------------------------
    //Crear un objeto para almacenar los codigos recepcionados por ciclo
    const codigosunicos = [...new Set(receptions.map((itemCod) => itemCod.Codigo))];   
    //console.log({codigosunicos: codigosunicos})     
    //Creo objeto nueva lista para almacenar codigo y concepto en un nuevaLista      
    const nuevaLista = codigosunicos.map(item1 => {
        const item2 = receptions.find(item2 => item2.Codigo === item1);            
        return {
            Codigo: item2.Codigo,
            Concepto: item2.Concepto
        };
    });    
    //Almacenar cod y nombre de producto para mostrarlos en el Dasboard con el index del cod de producto
    const nombreCodigos = {};
    nuevaLista.forEach((itemCod) => {
        if (!nombreCodigos[itemCod.Codigo]) {
            nombreCodigos[itemCod.Codigo] = '';
        }        
        nombreCodigos[itemCod.Codigo] += itemCod.Concepto;
    });
    //console.log({nombreCodigos: nombreCodigos})
    //Almacenar Ibm y nombre de sucursal para mostrarlos en el Dasboard con el index del ibm de la finca
    const nombreFinca = {};
    sucursals.forEach((item) => {
        if (!nombreFinca[item.strCodigo]) {
            nombreFinca[item.strCodigo] = '';
        }        
        nombreFinca[item.strCodigo] += item.strNombre;
    });
    //console.log({nombreFincas: nombreFinca})

    //2.Receptions--------------------------------------------------------------------------------------------------
    // Crear un objeto para almacenar los totales de recepciones por ciclo
    const receptionPorcodigo = {};
    // Calcular los totales de reception por ciclo
    receptions.forEach((item) => {
        if (!receptionPorcodigo[item.Codigo]) {
            receptionPorcodigo[item.Codigo] = {};
        }
        if (!receptionPorcodigo[item.Codigo][item.Ibm]) {
            receptionPorcodigo[item.Codigo][item.Ibm] = 0;
        }
        receptionPorcodigo[item.Codigo][item.Ibm] += item.total;
    });
    //console.log({receptionPorcodigo: receptionPorcodigo}) 

    //3.RDTs--------------------------------------------------------------------------------------------------
    //Recorro las recepciones y encuentro las aplicaciones realizadas e incluyo el campo aplicado con el total de la aplicacion por Ibm y Codigo 
    const nuevaListados = receptions.map(item1 => {
        const item2 = rdts.find(item2 => item2.Ibm === item1.Ibm && item2.Codigo === item1.Codigo);            
        return {
          ...item1,
          aplicado: item2 ? item2.total : 0,
          FINI: item2 ? item2.fechaMin : '',
          FFIN: item2 ? item2.fechaMax : '',
          Orden: item2 ? new Date(item2.fechaMin) : new Date(),
          Dias:  item2 ? Math.floor((Date.now() - new Date(item2.fechaMin)) / (1000 * 60 * 60 * 24)) : 0 //saco la diferencia de dias entre la fecha actual y la fecha de ini del ciclo
          //Dias: item2 ? Date.now() : 0
          
        };
    });  
    //console.log({nuevaListados: nuevaListados})  
    //Ordenar por fecha de inicio de aplicacion
    //Ordeno por finca que reporta
    const sortedList = [...nuevaListados].sort((a, b) =>  a['Orden'] - b['Orden']);
    //nuevaListados.sort((a, b) => b - a);
    //console.log({nuevaListadossort: sortedList})
    // Crear un objeto para almacenar los totales de aplicado    
    const aplicatePorcodigo = {};
    // Calcular los totales de aplicaciones en el ciclo consultado del año
    sortedList.forEach((item) => {
        if (!aplicatePorcodigo[item.Codigo]) {
            aplicatePorcodigo[item.Codigo] = {};
        }
        if (!aplicatePorcodigo[item.Codigo][item.Ibm]) {
            aplicatePorcodigo[item.Codigo][item.Ibm] = 0;
        }
        aplicatePorcodigo[item.Codigo][item.Ibm] += item.aplicado;
    });
    //console.log({rdtsAplicados: aplicatePorcodigo}) 

    
    //4.
    const conceptos = Object.keys(nombreCodigos);
    //console.log({conceptos: conceptos})
    const fincas = [...new Set(receptions.map((item) => item.Ibm))];
    //console.log({Ibms: fincas})
    const variables = ['RECIBIDO','APLICADO','DISPONIBLE'];
    
    //Sumar totales
    let totalReccibido = 0;
    let totalAplicado = 0;
    if(Object.keys(sortedList).length > 0 ){
        //console.log('en submitCant')
          let a = 0;
          let b = 0;                
          for (let i = 0; i < sortedList.length ; i++){          
              a = a + sortedList[i].total;
              b = b + sortedList[i].aplicado;                   
          }
          totalReccibido = a;
          totalAplicado = b;
                
    }else{
        totalReccibido = 0;
        totalAplicado = 0;
    }
    //console.log(totalReccibido, totalAplicado)
    //Capturo la fecha actual para realizar calculo de los dias a la fecha que se aplico el ciclo
    /*
    const fecha = new Date('2023-12-02T00:00:00.000Z');
    console.log({fecha: fecha})
    const tiempoTranscurrido = Date.now();
    const FInicia = new Date(fecha);
    const hoy = new Date(tiempoTranscurrido);
    console.log({fechaActual: hoy - (hoy -1)})
    //console.log({prueba: dateToNumber(Date.now())})
    const rango = (hoy - fecha);
    console.log({res: rango})
    const diferenciaEnDias = Math.floor(rango / (1000 * 60 * 60 * 24));
    console.log({diferencia: diferenciaEnDias})
    */
  return (
    <PropertyTableContainer>
         <br/>
         <PropertyTable>
            <thead>
                { modal?'': 
                    <PropertyTableRow>
                        <PropertyTableHeader><span>{nombreCodigos[conceptos[0]] || 0}</span></PropertyTableHeader>
                        <PropertyTableHeader>INICIA</PropertyTableHeader>
                        <PropertyTableHeader>TERMINA</PropertyTableHeader>
                        <PropertyTableHeader>RECI</PropertyTableHeader>
                        <PropertyTableHeader>APLI</PropertyTableHeader>
                        <PropertyTableHeader>DISP</PropertyTableHeader>
                        <PropertyTableHeader>DIAS</PropertyTableHeader>                                                
                    </PropertyTableRow>
                }
            </thead>
            <tbody>
                {           
                sortedList.map((item) => (
                        <PropertyTableRow key={item}>
                            <PropertyTableHeader>              
                                {item.Finca}                   
                            </PropertyTableHeader>
                            <PropertyTableCell>              
                                {item.FINI.split('T')[0]}                   
                            </PropertyTableCell>
                            <PropertyTableCell>              
                                {item.FFIN.split('T')[0]}                   
                            </PropertyTableCell>                        
                            <PropertyTableCell>              
                                {item.total}                   
                            </PropertyTableCell>                            
                            <PropertyTableCell>                                                             
                                <ButtonAplicate onClick={()=>handleModalAplicateFarmDos(item)}>
                                    {item.aplicado} 
                                </ButtonAplicate>                                                                                              
                            </PropertyTableCell>
                            <PropertyTableCell>              
                                <ButtonAplicate onClick={()=>handleModalAplicateFarm(item)}>
                                    {(item.total - item.aplicado)>0?<span>{(item.total - item.aplicado).toFixed(2)}</span>:(item.total - item.aplicado).toFixed(2)}
                                </ButtonAplicate>                   
                            </PropertyTableCell>
                            <PropertyTableCell>              
                                {item.Dias}       
                            </PropertyTableCell>                                                                    
                        </PropertyTableRow>
                    ))}                  
            </tbody>
            <tfoot>
                <PropertyTableRow>
                    <PropertyTableHeader></PropertyTableHeader>                                 
                    <PropertyTableCell></PropertyTableCell>                                                                                 
                    <PropertyTableCellTotal>Total:</PropertyTableCellTotal>                                                                            
                    <PropertyTableCellTotal>           
                        <>{totalReccibido}</>
                    </PropertyTableCellTotal>
                    <PropertyTableCellTotal>           
                        {totalAplicado}                   
                    </PropertyTableCellTotal>
                    <PropertyTableCell>           
                        <span>{(totalReccibido - totalAplicado).toFixed(2)}</span>
                    </PropertyTableCell>
                    <PropertyTableCell></PropertyTableCell>         

                </PropertyTableRow>
            </tfoot>
         </PropertyTable>
         {modal && <ModalAplicate
                    setModal={setModal}
                    animarModal={animarModal}
                    detail={idAplicate}/>                                            
         }
         {modaldos && <ModalAplicateDos
                    setModal={setModalDos}
                    animarModal={animarModal}
                    detail={idAplicate}/>                                            
         }
    </PropertyTableContainer>
  )
}


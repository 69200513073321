import React from 'react'
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../hooks/useAuth';
import { useRdt } from '../../../hooks/useRdt';
import { getToken, removeToken } from '../../../utils/TokenLS';


import { PropertyCardWrapper, PropertyCardWrapperTd, PropertyCardWrapperTr, PropertyDateWrapper, PropertyDescriptionWrapper, PropertyImageWrapper, PropertyInfoWrapper, PropertyValueWrapper } from "./styles";
import { SubTitle } from "../../../globalStyles";
import { HTTP_VERBS, requestHttp } from '../../../utils/HttpRequest';
import { showAlert, SW_ICON } from '../../../utils/swAlert';

//Visiualizar trabajadores de apoyo en una finca especifica con marcación y sin marcación
export const PropertyCardDial = ({ibm, fecha, bloque}) => {
  
  const { auth, closeSeccionAuth } = useAuth();
  //const { setRdtEmploye } = useRdt();
  const navigate = useNavigate();
  
  const [ employeesdials, setEmployeesdials ] = useState([]); 
  const [ traslate, setTraslate ] = useState([]);
  const [ news, setNews ] = useState([]);  
  const [ rdts, setRdts ] = useState([]);
  const [ employeesdialsdayfincaRdtApoyo, setEmployeesdialsdayfincaRdtApoyo ] = useState([]);
  const [ employeesdialsdayfincaRdtApoyosm, setEmployeesdialsdayfincaRdtApoyosm ] = useState([]);
  const [ employeesdialsdayfincaRdtApoyosort, setEmployeesdialsdayfincaRdtApoyosort ] = useState([]);
  const [ employeesdialsdayfincaRdtApoyosmsort, setEmployeesdialsdayfincaRdtApoyosmsort ] = useState([]);
  
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                
            await getTraslatesAll();                                                             
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[auth])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return 
            setEmployeesdials([]);          
            setTraslate([]);              
            setRdts([]);    
            setNews([]); 
            await getEmployeesdialsAll();            
            await getTraslatesAll();     
            await getRdts(); 
            await getNews();                                        
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[fecha])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                
            setEmployeesdials([]);          
            setTraslate([]);   
            setRdts([]); 
            setNews([]);   
            await getEmployeesdialsAll();            
            await getTraslatesAll();     
            await getRdts();     
            await getNews();                                            
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[ibm])

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return     
            setEmployeesdialsdayfincaRdtApoyo([]); 
            setEmployeesdialsdayfincaRdtApoyosm([]);                                                                          
            await getEmployeesdialsdayfincaRdt(); 
            await getEmployeesdialsdayfincaRdtsm();                        
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[employeesdials]) 

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return     
            setEmployeesdialsdayfincaRdtApoyo([]);   
            setEmployeesdialsdayfincaRdtApoyosm([]);                                                                          
            await getEmployeesdialsdayfincaRdt();  
            await getEmployeesdialsdayfincaRdtsm();                    
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[rdts]) 

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return     
            setEmployeesdialsdayfincaRdtApoyo([]);   
            setEmployeesdialsdayfincaRdtApoyosm([]);                                                                          
            await getEmployeesdialsdayfincaRdt();  
            await getEmployeesdialsdayfincaRdtsm();                    
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[news]) 

  useEffect(() => {  
    const getData = async ()=>{            
        try {
          const token = getToken();
            if(!token) return                               
            setEmployeesdialsdayfincaRdtApoyosort([]);     
            setEmployeesdialsdayfincaRdtApoyosmsort([]);           
            await getEmployeesdialsdayfincaApoyoRdtsort('strReportado', 'Ibm','intIBM_TRAS');                     
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[employeesdialsdayfincaRdtApoyosm])

  //getTraslates
  const getTraslatesAll = async ()=>{
    if(!fecha){                
      return
    //console.log('Fecha vacia')
    }    
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/traslates/rdts',                         
                params: makePropertiesFiltersTraslates()                                     
            }
        );             
        const {data} = response;
        //console.log({respuesta: response})                
        setTraslate(data);   
        //setRdtEmploye(data);                
    } catch (error) {            
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }            
    }        
  }
  const makePropertiesFiltersTraslates = ()=>{
    const filters = {};                
        filters['dtFInicial_RDT'] = fecha; 
        filters['dtFFinal_RDT'] = fecha; 
        filters['intIBM_TRAS'] = ibm;               
    return filters;       
  }
  //EmployeesDials
  const getEmployeesdialsAll = async ()=>{ 
    if(fecha){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/employeesdials',
                    params: makeEmployeesdialsFiltersAll()                                      
                }
            );             
            const {data} = response;                             
            await setEmployeesdials(data); 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }  
        }            
    }else{            
        return;
    }             
  }
  const makeEmployeesdialsFiltersAll = ()=>{
    const filters = {}; 
    filters['dtFecha'] = fecha; 
    //filters['Ibm'] = sucursalSeleted;      
    //console.log(fechaini,sucursalSeleted)
    return filters;       
  }
  //getRdts
  const getRdts = async ()=>{        
    if(!fecha){
        setRdts([])
        return
        //console.log('Fecha vacia')
    }
    if(ibm === '' || ibm === 0 || ibm === '90021' || ibm === '90020'){
        setRdts([])
        return
        //console.log({nohacerConsulta: sucursalSeleted})        
    }else{
        try {                      
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/Rdts/employeesibm',                         
                    params: makePropertiesFilters()                                     
                }
            );             
            const {data} = response;  
            //console.log({query: makePropertiesFilters() })        
            //console.log({rdts: data})               
            data?setRdts(data):console.log('');        
            } catch (error) {
                if(error.response.status === 401){
                    hendleCloseSeccion();
                }else{
                    console.log(error)
                }
            }
                
        }                   
  }
  const makePropertiesFilters = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = fecha; 
        filters['Fecha_FIN'] = fecha; 
        filters['IbmLaboro'] = ibm;   
        //filters['IntCodigo_EMPL'] = codtrabajador;    
    // filters['employeesId'] = auth.data._id;     
        //console.log(fechaini);
    return filters;       
  }
  //getNews
  const getNews = async ()=>{
    //console.log({sucursal: sucursalSeleted})
    if(!fecha){            
        setNews([])
    return
    //console.log('Fecha vacia')
    }
    
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/rdtsnews',                         
                params: makePropertiesFiltersNews()                                     
            }
        );             
        const {data} = response;                
        data?setNews(data):console.log('');                 
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
        
        //console.log({hacerConsulta: sucursalSeleted})        
            
  }
  const makePropertiesFiltersNews = ()=>{
    const filters = {};                
        filters['Fecha_INI'] = fecha; 
        filters['Fecha_FIN'] = fecha;                   
    return filters;       
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
  }
  //Comparo y encuentro trabajadores con marcacion que tienen Rdt.
  const getEmployeesdialsdayfincaRdt = async () =>{ 
    if(ibm === '90021' || fecha === ''){
        return
    } 
    //1.Recorro y encuentro los trabajadores prestados o trasladados a mi finca si tienen marcacion
    const conMarcacion = employeesdials.filter((item) => traslate.find((item2) => item.IntCodigo === item2.IntCodigo));
    //console.log({cMarca: conMarcacion})
    
    //2.Recorro y encuentro los trabajadores que les han reportado algún RDT y agrego la columna "strReportado"
    const nuevaLista = conMarcacion.map(item1 => {
        const item2 = rdts.find(item2 => item2.IntCodigo_EMPL === item1.IntCodigo);            
        return {
          ...item1,
          strReportado: item2 ? 'SI' : '',
          strFincaReporta: item2 ? item2.IbmLaboro : ''
        };
    });
    //2.2.Recorro la nueva lista de empleados que marcaron con los traslados y prestamos reportados; asigno el Motivo: "T"=Traslado ó "P"=Prestamo
    const nuevaListatres = nuevaLista.map(item1 => {
        const item2 = traslate.find(item2 => item2.IntCodigo === item1.IntCodigo);            
        return {
            ...item1,        
            strMotivo: item2 ? item2.strMotivo : ''       
        };
    });
    setEmployeesdialsdayfincaRdtApoyo(nuevaListatres)    
    //console.log({cMarcaRdt: nuevaLista})
  }
  //Comparo y encuentro trabajadores sin marcacion que tienen Rdt y novedades.
  const getEmployeesdialsdayfincaRdtsm = async () =>{ 
    if(ibm === '90021' || fecha === ''){
        return
    } 
    //console.log({Novedad: news})  
    //1. Recorro y encuentro los trabajadores prestados o trasladados a mi finca que no tienen marcación.
    const sinMarcacion = traslate.filter((item) => !employeesdials.find((item2) => item.IntCodigo === item2.IntCodigo));
    //console.log({sinMarca: sinMarcacion})
    //2. Recorro y encuentro los trabajadores sin marcación prestados o trasladados a mi finca, que ya tienen reportado RDTs.
    const nuevaLista = sinMarcacion.map(item1 => {
        const item2 = rdts.find(item2 => item2.IntCodigo_EMPL === item1.IntCodigo);            
        return {
          ...item1,
          strReportado: item2 ? 'SI' : '',
          strFincaReporta: item2 ? item2.IbmLaboro : ''
        };
    });    
    //3.Recorro y encuentro los trabajadores sin marcacion prestados o trasladados a mi finca, que tienen reportado novedades en su finca origen.
    const nuevaListados = nuevaLista.map(item1 => {
        const item2 = news.find(item2 => item2.IntCodigo_EMPL === item1.IntCodigo);            
        return {
          ...item1,
          strNews: item2 ? item2.strConcepto : ''
          //strFincaReporta: item2 ? item2.IbmLaboro : ''
        };
    });
    setEmployeesdialsdayfincaRdtApoyosm(nuevaListados) 
    //console.log({CUATROnoSort: employeesdialsdayfincaRdtApoyosm})  

  }
  //Ordeno por Rdt reportado y Finca que reporta
  const getEmployeesdialsdayfincaApoyoRdtsort = async (keyRdt, keyIbm, keyIBMTRAS) =>{ 
    if(ibm === '90021' || fecha === ''){
        return
    }     
    //Ordeno por finca que reporta
    const sortedList = [...employeesdialsdayfincaRdtApoyo].sort((a, b) => a[keyIbm] > b[keyIbm] ? 1 : -1);
    //Ordeno por Trabajador que fue reportado.
    const sortedListdos = [...sortedList].sort((a, b) => a[keyRdt] > b[keyRdt] ? 1 : -1);
    setEmployeesdialsdayfincaRdtApoyosort(sortedListdos)

    //Ordeno por finca que reporta para sin marcación
    const sortedListtres = [...employeesdialsdayfincaRdtApoyosm].sort((a, b) => a[keyIBMTRAS] > b[keyIBMTRAS] ? 1 : -1);
    setEmployeesdialsdayfincaRdtApoyosmsort(sortedListtres)
    //console.log({CUATRO: employeesdialsdayfincaRdtApoyosmsort})  

  }  

  return (
    
  <>    
      <PropertyCardWrapperTr>
            <PropertyCardWrapperTd>
                <h3>PRESTAMOS DE OTRAS FINCAS CON MARCACIÓN: <span>{employeesdialsdayfincaRdtApoyosort.length}</span></h3>                
                <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>COD</th>
                                <th>NOMBRE</th>
                                <th>H.INI</th>
                                <th>H.FIN</th>
                                <th>T.LAB</th> 
                                <th>MOTIVO</th>
                                <th>FINCA</th>   
                                <th>RDT</th>
                                <th>REPORTA</th>                           
                            </tr>
                        </thead>
                        <tbody>
                            {employeesdialsdayfincaRdtApoyosort.map((elemento, key) => (
                            <tr>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {key + 1}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.IntCodigo}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strNombreTrabajador}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strHoraIngreso}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strHoraSalida}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strTiempoLaborado}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strMotivo}
                                    </PropertyDescriptionWrapper>                                
                                </th>                                
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.Ibm}
                                    </PropertyDescriptionWrapper>                                
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                    {elemento.strReportado}
                                    </PropertyDescriptionWrapper>                                
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strFincaReporta}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                            </tr>
                            ))}                
                        </tbody>
                    
                </table>
            </PropertyCardWrapperTd>                    
      </PropertyCardWrapperTr>
      <PropertyCardWrapperTr>
            <PropertyCardWrapperTd>
                <h3>PRESTAMOS DE OTRAS FINCAS <b>SIN MARCACIÓN:</b> <span>{employeesdialsdayfincaRdtApoyosmsort.length}</span></h3>
                <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>COD</th>
                                <th>NOMBRE</th>
                                <th>MOTIVO</th>                                                                            
                                <th>FINCA</th>                                 
                                <th>RDT</th>
                                <th>REPORTA</th>
                                <th>NOV</th>                        
                            </tr>
                        </thead>
                        <tbody>
                            {employeesdialsdayfincaRdtApoyosmsort.map((elemento, key) => (
                            <tr>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {key + 1}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        <span>{elemento.IntCodigo}</span>
                                    </PropertyDescriptionWrapper>
                                    
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strNombreTrabajador}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>     
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strMotivo}<a>{elemento.dtFInicial.split('T')[0]}</a>  
                                    </PropertyDescriptionWrapper>
                                    
                                </th>                                                                              
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strCodigoCentro_EMPL}
                                    </PropertyDescriptionWrapper>
                                    
                                </th> 
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strReportado}
                                    </PropertyDescriptionWrapper>                                
                                </th>
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strFincaReporta}
                                    </PropertyDescriptionWrapper>
                                    
                                </th>    
                                <th>
                                    <PropertyDescriptionWrapper>
                                        {elemento.strNews}
                                    </PropertyDescriptionWrapper>                                    
                                </th>                    
                            </tr>
                            ))}                
                        </tbody>
                    
                </table>
            </PropertyCardWrapperTd>
      </PropertyCardWrapperTr>
  </>
  
  )
}
import styled from 'styled-components';

export const PropertyTypesContainerHead = styled.section`
    
    //border: 1px solid red;
    display: flex;  
    margin: 10px 0 ;    
    
`
export const PropertyTypesContainerColum = styled.section`

    //border: 1px solid yellow;
    padding-right: 20px;
    

`
export const PropertyTypesContainerRow = styled.section`

    //border: 1px solid red;
    display: flex;

`
export const PropertyTypesContainer = styled.section`
    //border: 1px solid orange;
    display: flex;
    overflow-x: scroll; 
    
`;

export const PropertyInfoWrapper = styled.div`
    flex: 70;
    //border: 1px solid red;
    margin: 0 10px 0 10px;
    text-align: left;
    color:#6262A0;

    h4{
        color: #26794f;
        span{
            color: black;
        }
    }
    
        
`;

export const DistributionDetailTitle = styled.h1`
    font-size: 1.5em;
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    font-weight: bold;
    //border: 1px solid blue;
    width: 100%;
    
`;

export const DistributionDetailInfo = styled.div`
    span{
        font-size: 1.5em;
        font-weight: bold;        
    }
    p{
        text-align: left; 
        span{
            font-size: 1.1em;
            font-weight: bold;
        }
    }
    
    //color: #222;
    color: #26794f;
    font-weight: 300;
    text-align: center;
    
    //border: 1px solid blue;
    width: 100%;
    
`;

export const PropertyApp = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 20px;
`;

export const PropertyWorkerslistGroup = styled.div`
    //border: 1px solid red;
    font-size: 0.6em;
    width: 400px;
    max-height: 600px;
    overflow-y: auto;
    border: 2px solid #ccc;
    padding: 5px;
    border-radius: 8px;

    h2{
        text-align: center;
    }
    button{           
        background-color: #f0a808;
        border: 1px solid #f0a808;
        margin-left: 10px;
        font-size: 1em;
    }
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;    
        button{
            cursor: pointer;                    
        }
    }
`;

export const PropertyWorker = styled.div`
    margin: 5px 0;
    padding: 5px;
    background-color: #f4f4f4;
    border-radius: 4px;
    text-align: center;

    &:hover{        
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff};      
    }
    
`;

export const PropertyGroup = styled.div`
    //border: 1px solid red;
    background-color: #f9f9f9;
`;

export const PropertysubGroup = styled.div`
    border: 2px solid #ccc;
    padding: 5px;

    h2{
        text-align:center;
    }
`;

export const PropertyItem = styled.div`
    //border: 1px solid red;  
    display: flex;
    justify-content: space-between;  
    align-items: center;
    font-size: 0.9em;
    padding-left: 5px;
    color: ${(props) => (props.centro && props.centro.toLowerCase().includes('prestamos') ? 'blue' : 'black')};
    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
    button{           
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#f0a808')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '1px solid #f0a808')};
        margin: 0.5px;
        font-size: 1em;        
    }
`;

export const PropertyItemButton = styled.div`
    //border: 1px solid red;

    &:hover{
        //cursor: pointer;
        //background-color: #4A148C;
        //background-color: #f0a808;
        svg{
            color: #fff;            
        }
        p{color:#fff}
        button{
            cursor: pointer; 
            background-color: red;                  
        }
    }
        
    button{         
        padding-left: 5px;          
        background-color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'green' : '#808080')};
        border: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? '1px solid green' : '4px solid #f9f9f9')};
        color: ${(props) => (props.marcacion && props.marcacion.toLowerCase().includes('si') ? 'blue' : 'white')};
        margin: 0.5px;
        font-size: 1em;   
        
        .tooltip {
            visibility: hidden;
            background-color: black;
            color: white;
            text-align: center;
            padding: 5px;
            border-radius: 5px;
            position: absolute;
            bottom: 125%; /* Posiciona el tooltip arriba del botón */
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            z-index: 1;
        }
    }

`;

export const FormControlInputSearch = styled.div`
    //border: 1px solid red;
    display: flex;   
    //margin: 0 10px 0 10px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;

    //Para Tablet
    @media (max-width: 1054px){
        //border: 1px solid blue;
        margin: 0 2px 0 2px;
        //margin-top: 5px;
        //padding: 5px;            
        //justify-content: center;
        //width: 100%;            
    }

    label{
        display: block;
        font-size: 0.80em;
        color: #26794f;
        font-weight: 500;
        padding-left: 10px ;
        margin-left: 10px;
                    
    }
    input[type="email"], input[type="password"],
    input[type="text"], input[type="number"],
    input[type="float"],input[type="date"]{
        //display: block;
        width: 30%;
        //border-radius: 10px;
        font-size: 1.1em;
        padding: 5px 10px;
        margin: 5px;
        background-color: #fff;
        border-radius: 15px;
        outline: none;    

    }

    select{
        //display: block;
        width: 100%;
        border-radius: 10px;
        font-size: 0.9em;
        padding: 5px 10px;
        margin: 0px;
        background-color: #fff;
        border: none;
        outline: none;        

    }

    span{
        color: red;
        font-size: 0.85em;
    }
`;

export const TextareaContainer = styled.div`
  margin-top: 20px;
`;

export const ObservationsTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  box-sizing: border-box;
`;

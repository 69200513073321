import { useEffect, useState } from "react";
import { useAuth } from '../../../../hooks/useAuth';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { IoCloseCircleOutline, IoBookmark } from "react-icons/io5";
import { CerrarModal, 
         FormControlModal, 
         FormularioModal, 
         ModalDetailDescriptionWrapper, 
         ModalDetailValueWrapper, 
         ModalPropertyTypesContainerColum, 
         ModalPropertyTypesContainerRow, 
         PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellFonts, 
         PropertyTableHeader, 
         PropertyTableRow, 
         TittleModal } from "./styles";
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { ButtonIconRibbon } from "../../../../components/ButtonIconRibbon";

const suma = 0;

const colors = [ 
  {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
  {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
  {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
  {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
  {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
  {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
  {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
  {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
  {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
  {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const ModalLoteGuan = ({setModalLoteGuan, animarModalLoteGuan, setAnimarModalLoteGuan, detail, lote, calendario, fechafin, embLote, guaLote, global, ibm, sucursalName}) => {
  
  //console.log('estoy en modal') 
  //console.log({detailModal: detail})
  //console.log({calendarioModal: calendario})
  //console.log({ffinModal: fechafin})
  //console.log({globalModal: global})
  //console.log({ibmModal: ibm})
  //console.log({sucursalNameModal: sucursalName})    

  const { auth, closeSeccionAuth } = useAuth();

  //Buscar analisis de rdts por ibm, cinta, lote, rango fecha especifico para Guantelete
  const [ rdtsudpsGua, setRdtsudpsGua ] = useState([]);
  //Tiempo para realizar el cargue visual de las consultas
  const [ estado, setEstado ] = useState(false)  

  const Cargar = ()=>{
    setTimeout(()=>{                                  
         setEstado(true)
         //console.log({estado: estado})  
         //setRdtsudpsGuaFinal(Rdtsudploteespecifico[0].documentos)                             
         //console.log(rdtsudpsGuafinal)
    }, 200)           
  }
  //cargar data
  useEffect(() => {  
    if(!estado){
      Cargar()
      //console.log({Rdtsudploteespecifico: Rdtsudploteespecifico[0].total})
      //console.log('Inicia el cargue')
    }               
  },[rdtsudpsGua])
      
  let detailCinta = {}
  detailCinta = (detail)
    
  let idLote = {}
  idLote = (lote)

  let idFFin = {}
  idFFin = (fechafin)

  let idembLote = {}
  idembLote = (embLote)

  let idguaLote = {}
  idguaLote = (guaLote)

  //1. filtramos los objetos con detailCinta.semana igual a la sem, para mostrar la fecha inicial en la que se inicio a embolsar y poder enviar a consultar
  const calendarSem = calendario.filter(objeto => objeto.semana == detailCinta.semana );    
  //console.log({calendarSem: calendarSem[0].fini })

useEffect(() => {
    const getData = async ()=>{
        //console.log('obtener calendar');
        try {
            const token = getToken();
            if(!token) return                
            //await  cleanStates();                                           
            await getRdtsUdpGua();
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
},[detail, lote, calendario])

//getRdts x udp especifico x cinta especifica x rango especifico, para Guantelete
const getRdtsUdpGua = async ()=>{      
  //console.log('haciendo la consulta de rdtsudps') 
  //console.log({filters: calendar[0].fini}); 
  if(!calendario[0]){
      setRdtsudpsGua([])
      //console.log('Fecha Ini vacia')
      return
  }                            
  if(!fechafin){
      setRdtsudpsGua([])
      //console.log('Fecha vacia')
      return                              
  }else{
      
    try {  
                        
      const response = await requestHttp(
          {
              method: HTTP_VERBS.GET,
              endpoint: '/Rdts/analysesudps',                                               
              params: makePropertiesFiltersGua()                                     
          }
      );             
      const {data} = response;                
      data?setRdtsudpsGua(data):console.log('');
      //console.log({rdtsUdpsGua: data})        
      } catch (error) {
          if(error.response.status === 401){                    
              
          }else{
              //console.log('ErrorRdtsUdps')
              console.log(error)
          }
      }
        //console.log({hacerConsulta: sucursalSeleted})        
  }                   
}

const makePropertiesFiltersGua = ()=>{
  const filters = {}; 
      //console.log({filtersIni: 'Iniciando'});      
      filters['Fecha_INI'] = calendarSem[0].fini.split('T')[0];            
      filters['Fecha_FIN'] = fechafin; 
      filters['IbmLaboro'] = ibm;   
      filters['IntCodigo_CONC'] = global[0].Codigo;
      filters['tipo'] = 'invtres';
      filters['strCinta_UDP'] = detailCinta.cintaName;
      filters['IntCodigo_UDP'] = idLote;                             
  // filters['employeesId'] = auth.data._id;     
  //console.log({filters: calendar[0].fini.split('T')[0]});
  //console.log({filters: filters});
  
  return filters;       
}

//2. filtramos los objetos con detailCinta.semana igual a la sem, para mostrar la fecha inicial en la que se inicio a embolsar y poder enviar a consultar
const Rdtsudploteespecifico =  rdtsudpsGua.filter(objeto => objeto.Lote == idLote && objeto.cinta == detailCinta.cintaName );    
//console.log({Rdtsudploteespecifico: Rdtsudploteespecifico})


//Mostrar
//3. Función para mostrar el color de la cinta del guantelete en un circulo obalado
const analisisColors = {};

//Para el color de las cintas en el encabezado
colors.forEach(item=>{
  analisisColors[item.name] = {cinta: item.cinta, nameCorto: item.nameCorto}
})
//console.log({analisisColors:analisisColors})    
  
//Boton de cerrar Modal
const ocultarModal = ()=>{      
  //setAnimarModal(false)
  setModalLoteGuan(false)          
}

  return (
    <FormControlModal>
      <CerrarModal>
        <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
      </CerrarModal> 
      {animarModalLoteGuan?
        <FormularioModal>
          <ModalDetailDescriptionWrapper>
            <ModalPropertyTypesContainerRow>
              <ModalPropertyTypesContainerColum>
                  <ModalDetailValueWrapper>
                        <h2>{sucursalName} // Lote: {idLote}  // Cinta: {detailCinta.cintaName} <ButtonIconRibbon icon={IoBookmark} cinta={analisisColors[detailCinta.cintaName].cinta} /> // Sem: {detailCinta.semana}</h2>                                                
                                                
                        <PropertyBusinessType
                            busineesType={`Racimos Embolsados: ${idembLote}`}
                        />                        
                        <PropertyBusinessType
                            busineesType={`Guanteletes Reporta: ${idguaLote} `}
                        />
                        {(idembLote - idguaLote<0?                          
                            <PropertyBusinessType                              
                                  busineesType={<PropertyTableCellFonts color={'red'}>{`Diferencia: ${idembLote - idguaLote} ` }</PropertyTableCellFonts>}                              
                            />
                        :
                            <PropertyBusinessType
                                busineesType={`Diferencia: ${idembLote - idguaLote} ` }
                            />
                        )}
                        
                                                  
                  </ModalDetailValueWrapper>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>            
            <ModalPropertyTypesContainerRow>                                 
              <ModalPropertyTypesContainerColum>
                  <h3>{sucursalName} // {global[0].Codigo}-{global[0].Labor}</h3>                                                
                  <PropertyBusinessType                        
                      busineesType={`FINI: ${calendarSem[0].fini.split('T')[0]}`}
                  />
                  <PropertyBusinessType                        
                      busineesType={`FFIN: ${idFFin}`}
                  />
                  <br/>              
                  <PropertyTable>
                    <thead>
                      <PropertyTableRow>                
                          <PropertyTableHeader>CODIGO</PropertyTableHeader>
                          <PropertyTableHeader>NOMBRE</PropertyTableHeader>                  
                          <PropertyTableHeader>LABOR</PropertyTableHeader>
                          <PropertyTableHeader>UDP</PropertyTableHeader> 
                          <PropertyTableHeader>CINTA</PropertyTableHeader>                                                                          
                          <PropertyTableHeader>FECHA</PropertyTableHeader>                                                
                          <PropertyTableHeader>CANTI</PropertyTableHeader>
                          <PropertyTableHeader>REPORTA</PropertyTableHeader>
                      </PropertyTableRow>
                    </thead>
                    <tbody>
                      {estado?
                        Rdtsudploteespecifico[0]?
                        Rdtsudploteespecifico[0].documentos.map((item) => (
                          <PropertyTableRow key={item}>
                              <PropertyTableHeader>              
                                  {item.IntCodEMPL}                   
                              </PropertyTableHeader>
                              <PropertyTableCell>              
                                  {item.strNombreTrabajador}                   
                              </PropertyTableCell>                                                   
                              <PropertyTableCell>              
                                  {item.IntCodigo_CONC}                   
                              </PropertyTableCell>                            
                              <PropertyTableCell>              
                                  {item.strNombre_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.strCinta_UDP}                   
                              </PropertyTableCell> 
                              <PropertyTableCell>              
                                  {(item.dtmFecha_RDT).split('T')[0]}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.intCantidadTotal_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.email_USUA.split('@')[0]}                   
                              </PropertyTableCell>                                                                                              
                          </PropertyTableRow>
                      )):''
                      :''}
                    </tbody>
                  </PropertyTable>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>
          </ModalDetailDescriptionWrapper>        
                      
        </FormularioModal>        
        :''}
    </FormControlModal>
  )
}
import { useEffect, useState } from "react";
import { useAuth } from '../../../../hooks/useAuth';
import { getToken, removeToken } from '../../../../utils/TokenLS';
import { HTTP_VERBS, requestHttp } from '../../../../utils/HttpRequest';
import { ButtonIcon } from "../../../../components/ButtonIcon";
import { IoCloseCircleOutline, IoBookmark } from "react-icons/io5";
import { CerrarModal, 
         FormControlModal, 
         FormularioModal, 
         ModalDetailDescriptionWrapper, 
         ModalDetailValueWrapper, 
         ModalPropertyTypesContainerColum, 
         ModalPropertyTypesContainerRow, 
         PropertyContainer, 
         PropertyTable, 
         PropertyTableCell, 
         PropertyTableCellFonts, 
         PropertyTableHeader, 
         PropertyTableRow, 
         TittleModal } from "./styles"
import { PropertyBusinessType } from "../../../../components/PropertyBusinessType";
import { ButtonIconRibbon } from "../../../../components/ButtonIconRibbon";

const suma = 0;

const colors = [ 
  {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
  {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
  {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
  {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
  {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
  {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
  {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
  {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
  {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
  {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const ModalLoteEmb = ({setModalLoteEmb, animarModalLoteEmb, setAnimarModalLoteEmb, semana, lote, codLabor, calendario, fechaini, fechafin, embPM, embPS, embLote, global, ibm, sucursalName}) => {

  console.log('estoy en modal EMBOLSE') 
  console.log({codLaborModal: codLabor})
  console.log({semanaModal: semana})
  console.log({loteModal: lote})
  console.log({embLoteModal: embLote})
  console.log({calendarioModal: calendario})
  console.log({finiModal: fechaini})
  console.log({ffinModal: fechafin})
  console.log({globalModal: global})
  console.log({ibmModal: ibm})
  console.log({sucursalNameModal: sucursalName})    

  const { auth, closeSeccionAuth } = useAuth();

  //Buscar analisis de rdts por ibm, lote, rango fecha especifico para embolse
  const [ rdtsudpsEmb, setRdtsudpsEmb ] = useState([]);
  //Tiempo para realizar el cargue visual de las consultas
  const [ estado, setEstado ] = useState(false)  

  const Cargar = ()=>{
    setTimeout(()=>{                                  
         setEstado(true)
         //console.log({estado: estado})  
         //setRdtsudpsGuaFinal(Rdtsudploteespecifico[0].documentos)                             
         //console.log(rdtsudpsGuafinal)
    }, 200)           
  }
  //cargar data
  useEffect(() => {  
    if(!estado){
      Cargar()
      //console.log({Rdtsudploteespecifico: Rdtsudploteespecifico[0].total})
      //console.log('Inicia el cargue')
    }               
  },[rdtsudpsEmb])

  useEffect(() => {
    const getData = async ()=>{
        //console.log('obtener calendar');
        try {
            const token = getToken();
            if(!token) return                
            //await  cleanStates();                                           
            await getRdtsUdpEmb();
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
},[lote, codLabor, calendario])

//getRdts x udp especifico x rango especifico, para Embolse
const getRdtsUdpEmb = async ()=>{      
  console.log('haciendo la consulta de rdtsudps') 
  //console.log({filters: calendar[0].fini}); 
  if(!fechaini){
      setRdtsudpsEmb([])
      console.log('Fecha Ini vacia')
      return
  }                            
  if(!fechafin){
      setRdtsudpsEmb([])
      console.log('Fecha vacia')
      return                              
  }else{
      
    try {  
        console.log('haciendo la consulta de rdtsudps try')                   
      const response = await requestHttp(
          {
              method: HTTP_VERBS.GET,
              endpoint: '/Rdts/analysesudps',                                               
              params: makePropertiesFiltersEmb()                                     
          }
      );             
      const {data} = response;                
      data?setRdtsudpsEmb(data):console.log('');
      //console.log({rdtsUdpsEmb: data})        
      } catch (error) {
          if(error.response.status === 401){                    
              
          }else{
              //console.log('ErrorRdtsUdps')
              console.log(error)
          }
      }
        //console.log({hacerConsulta: sucursalSeleted})        
  }                   
}

const makePropertiesFiltersEmb = ()=>{
  const filters = {}; 
      //console.log({filtersIni: 'Iniciando'});      
      filters['Fecha_INI'] = fechaini;            
      filters['Fecha_FIN'] = fechafin; 
      filters['IbmLaboro'] = ibm;   
      filters['IntCodigo_CONC'] = global[0].Codigo;
      filters['tipo'] = 'invcuatro';
      //filters['strCinta_UDP'] = detailCinta.cintaName;
      filters['IntCodigo_UDP'] = lote;                             
  // filters['employeesId'] = auth.data._id;     
  //console.log({filters: calendar[0].fini.split('T')[0]});
    console.log({filters: filters});
  
  return filters;       
 }

 //2. filtramos los objetos con detailCinta.semana igual a la sem, para mostrar la fecha inicial en la que se inicio a embolsar y poder enviar a consultar
 const Rdtsudploteespecifico =  rdtsudpsEmb.filter(objeto => objeto.Lote == lote);    
 //console.log({Rdtsudploteespecifico: Rdtsudploteespecifico})


  //Mostrar
  //3. Función para mostrar el color de la cinta del guantelete en un circulo obalado
  const analisisColors = {};

  //Para el color de las cintas en el encabezado
  colors.forEach(item=>{
      analisisColors[item.id] = {cinta: item.cinta, nameLargo:item.name, nameCorto: item.nameCorto}
  })

    //4. Función para mostrar unicamente los datos del area del lote
    const analisisAreaLote = {};

    //Para las areas del lote
    global.forEach(item=>{
        analisisAreaLote[item.Lote] = {A_Bruta: item.A_Bruta, A_Neta:item.A_Neta, realizado: item.realizado}
    })
  
  console.log({analisisColors:analisisColors})  
  console.log({calendarioPM:calendario[0].cintaPm}) 
  console.log({analisisAreaLote: analisisAreaLote}) 

  //Boton de cerrar Modal
  const ocultarModal = ()=>{      
    //setAnimarModal(false)
    setModalLoteEmb(false)          
  }

  return (
     <FormControlModal>
      <CerrarModal>
        <ButtonIcon icon={IoCloseCircleOutline} onPress={ocultarModal} />
      </CerrarModal> 
      {animarModalLoteEmb?
        <FormularioModal>
          <ModalDetailDescriptionWrapper>
            <ModalPropertyTypesContainerRow>
              <ModalPropertyTypesContainerColum>
                 <ModalDetailValueWrapper>
                        <h2>{sucursalName} // Sem: {semana} // Lote: {lote} // ABruta: {analisisAreaLote[lote].A_Bruta} // ANeta: {analisisAreaLote[lote].A_Neta}  </h2>                                                                                                                                                                        
                  </ModalDetailValueWrapper>
                  <PropertyContainer>
                    <ModalDetailValueWrapper>
                            <h2>{analisisColors[calendario[0].cintaPm].nameLargo} <ButtonIconRibbon icon={IoBookmark} cinta={analisisColors[calendario[0].cintaPm].cinta} /> </h2>
                            <PropertyBusinessType
                                busineesType={`PM: ${embPM}`}                            
                            />                                                                                                
                    </ModalDetailValueWrapper>
                    <ModalDetailValueWrapper>
                            <h2>{analisisColors[calendario[0].cintaPs].nameLargo} <ButtonIconRibbon icon={IoBookmark} cinta={analisisColors[calendario[0].cintaPs].cinta} /> </h2>
                            <PropertyBusinessType
                                busineesType={`PS: ${embPS}`}                            
                            />                                                                                                
                    </ModalDetailValueWrapper>
                    <ModalDetailValueWrapper>
                            <br/>
                            <br/>
                            <PropertyBusinessType
                                busineesType={`TOTAL PM+PS: ${embLote}`}                            
                            />                                                                                                                          
                    </ModalDetailValueWrapper>
                    <ModalDetailValueWrapper>
                            <br/>
                            <br/>
                            <PropertyBusinessType
                                busineesType={`AREA REC: ${(analisisAreaLote[lote].realizado).toFixed(2)}`}                            
                            />                                                                                                                            
                    </ModalDetailValueWrapper>
                    <ModalDetailValueWrapper>
                            <br/>
                            <br/>
                            <PropertyBusinessType
                                busineesType={`B/H: ${(embLote/analisisAreaLote[lote].A_Neta).toFixed(0)}`}                            
                            />                                                                                                                            
                    </ModalDetailValueWrapper>
                  </PropertyContainer>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>            
            <ModalPropertyTypesContainerRow>                                 
              <ModalPropertyTypesContainerColum>
                  <h3>{sucursalName} // {global[0].Codigo}-{global[0].Labor}</h3>                                                
                  <PropertyBusinessType                        
                      busineesType={`FINI: ${fechaini}`}
                  />
                  <PropertyBusinessType                        
                      busineesType={`FFIN: ${fechafin}`}
                  />
                  <br/>              
                  <PropertyTable>
                    <thead>
                      <PropertyTableRow>                
                          <PropertyTableHeader>CODIGO</PropertyTableHeader>
                          <PropertyTableHeader>NOMBRE</PropertyTableHeader>                  
                          <PropertyTableHeader>LABOR</PropertyTableHeader>
                          <PropertyTableHeader>UDP</PropertyTableHeader> 
                          <PropertyTableHeader>FECHA</PropertyTableHeader>                                                
                          <PropertyTableHeader>CINTA</PropertyTableHeader>
                          <PropertyTableHeader>TIPO</PropertyTableHeader>                                                                                                                                                                              
                          <PropertyTableHeader>CANTI</PropertyTableHeader>
                          <PropertyTableHeader>A_REC</PropertyTableHeader>
                          <PropertyTableHeader>B/H</PropertyTableHeader>
                          <PropertyTableHeader>REPORTA</PropertyTableHeader>
                      </PropertyTableRow>
                    </thead>
                    <tbody>
                        {estado?
                        Rdtsudploteespecifico[0]?
                        Rdtsudploteespecifico[0].documentos.map((item) => (
                          <PropertyTableRow key={item}>
                              <PropertyTableHeader>              
                                  {item.IntCodEMPL}                   
                              </PropertyTableHeader>
                              <PropertyTableCell>              
                                  {item.strNombreTrabajador}                   
                              </PropertyTableCell>                                                   
                              <PropertyTableCell>              
                                  {item.IntCodigo_CONC}                   
                              </PropertyTableCell>                            
                              <PropertyTableCell>              
                                  {item.strNombre_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {(item.dtmFecha_RDT).split('T')[0]}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.strCinta_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.strTipoCinta_UDP}                   
                              </PropertyTableCell> 
                              <PropertyTableCell>              
                                  {item.intCantidad_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.intCantidadTotal_UDP}                   
                              </PropertyTableCell>
                              <PropertyTableCell>              
                                  {item.intCantidadTotal_UDP>0?(item.intCantidad_UDP/item.intCantidadTotal_UDP).toFixed(0):0}                   
                              </PropertyTableCell>                                                           
                              <PropertyTableCell>              
                                  {item.email_USUA.split('@')[0]}                   
                              </PropertyTableCell>                                                                                                                         
                          </PropertyTableRow>
                      )):''
                      :''}
                    </tbody>
                  </PropertyTable>
              </ModalPropertyTypesContainerColum>
            </ModalPropertyTypesContainerRow>
          </ModalDetailDescriptionWrapper>        
                      
        </FormularioModal>        
        :''}
    </FormControlModal>
  )
}
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { useRdt } from "../../../hooks/useRdt";
import swal from 'sweetalert';
import Alert from "../../../components/Alert/Alert";
import { IoTrashOutline, 
        IoRemoveCircleOutline,        
        IoArrowBack,
        IoDuplicate,
        IoSendSharp,
        IoSave,
        IoBookmark } from "react-icons/io5";
import { HTTP_VERBS, requestHttp } from "../../../utils/HttpRequest";
import { showAlert, SW_ICON } from "../../../utils/swAlert";
import { getToken, removeToken } from "../../../utils/TokenLS";
import { PropertyBusinessType } from "../../../components/PropertyBusinessType";
import { PropertyTypeLabel } from "../../../components/PropertyTypeLabel";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { ButtonIconRemove } from "../../../components/ButtonIconRemove";
import { ButtonIconUpdateAll } from "../../../components/ButtonIconUpdateAll";
import { Page } from "../../../components/Page";
import { PageTitle } from "../../../globalStyles";
import { FormControl,          
         FormControlAction, 
         FormControlInputSearch, 
         FormControlInputSearchudp, 
         FormControlInputudp, 
         PropertyTypesContainerColum, 
         PropertyTypesContainerRow, 
         RdtDetailDescriptionWrapper, 
         RdtDetailInfoWrapper, 
         RdtDetailSubTitle,         
         RdtDetailValueWrapper, 
         RdtDetailWrapper, 
         RdtDetailWrapper_Global, 
         RdtDetailWrapper_Global_DOS, 
         LogoImageWrapper,
         FormControlActionOne,
         FormControlActionTwo,
         FormControlHead,
         FormControlInputTitel,
         FormControlInputSearchcinta,
         FormControlInputSearchudpdel,
         FormControlSeccionOne,
         FormControlSeccionTwo} from "./styles";
import { ButtonIconRibbon } from "../../../components/ButtonIconRibbon";


const ALL_SUCURSALES = 0;
const HORA = 9.5;

let cinta = 'transparent', cintados = 'transparent', cintatres = 'transparent', cintacuatro = 'transparent', cintaPm = 0, cintaPs = 0;
let nameCinta='', nameCintados='', nameCintatres='', nameCintacuatro='', nameCintaPm='', nameCintaPs='';
let nameCintaCorto='', nameCintadosCorto='', nameCintatresCorto='', nameCintacuatroCorto='';

const colors = [ 
    {id:1, cinta:'brown', name:'CAFE', nameCorto:'CA'},
    {id:2, cinta:'black', name:'NEGRA', nameCorto:'NE'}, 
    {id:3, cinta:'orange', name:'NARANJA', nameCorto:'NA'}, 
    {id:4, cinta:'green', name:'VERDE', nameCorto:'VE'}, 
    {id:5, cinta:'yellow', name:'AMARILLA', nameCorto:'AM'},   
    {id:6, cinta:'white', name:'BLANCA', nameCorto:'BL'},
    {id:7, cinta:'blue', name:'AZUL', nameCorto:'AZ'},
    {id:8, cinta:'gray', name:'GRIS', nameCorto:'GR'},   
    {id:9, cinta:'purple', name:'MORADA', nameCorto:'MO'},    
    {id:10, cinta:'red', name:'ROJA', nameCorto:'RO'},   
];

export const RdtDetail = () => {

  const { auth, closeSeccionAuth } = useAuth();
  const { setRdtprov } = useRdt();
  const { id } = useParams();

  const [ rdt, setRdt ] = useState([]);    
  const [ conceptoSeleted, setConceptoSelected] = useState('');
  const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);   
  const [ sumcantidad, setSumcantidad ] = useState(0);    
  const [ horas, setHoras ] = useState(0);
  const [ observacion, setObservacion ] = useState('');
  const [ date, setDate ] = useState('');

  const [ rdtstrabajador, setRdtstrabajador ] = useState([]);
  const [ concepto, setConcepto ] = useState([]);
  const [ udpuno, setUdpuno ] = useState([]);
  const [ udpdos, setUdpdos ] = useState([]);
  const [ udptres, setUdptres ] = useState([]);
  const [ udpcuatro, setUdpcuatro ] = useState([]);

  const [udpunoselected, setUdpunoselected] = useState(0);
  const [udpdosselected, setUdpdosselected] = useState(0);
  const [udptresselected, setUdptresselected] = useState(0);
  const [udpcuatroselected, setUdpcuatroselected] = useState(0);

  const [cantudpunoselected, setCantudpunoselected] = useState(0);
  const [cantudpdosselected, setCantudpdosselected] = useState(0);
  const [cantudptresselected, setCantudptresselected] = useState(0);
  const [cantudpcuatroselected, setCantudpcuatroselected] = useState(0);
  
  //Se utilizan para registrar el cod 118 Embolse y especificar cinta Presente
  const [cantudpunoPsselected, setCantudpunoPsselected] = useState(0);
  const [cantudpdosPsselected, setCantudpdosPsselected] = useState(0);
  const [cantudptresPsselected, setCantudptresPsselected] = useState(0);
  const [cantudpcuatroPsselected, setCantudpcuatroPsselected] = useState(0);
  //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura
  const [cantudpunoPmselected, setCantudpunoPmselected] = useState(0);
  const [cantudpdosPmselected, setCantudpdosPmselected] = useState(0);
  const [cantudptresPmselected, setCantudptresPmselected] = useState(0);
  const [cantudpcuatroPmselected, setCantudpcuatroPmselected] = useState(0);
  //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura+Presente
  const [cantudpunoPPselected, setCantudpunoPPselected] = useState(0);
  const [cantudpdosPPselected, setCantudpdosPPselected] = useState(0);
  const [cantudptresPPselected, setCantudptresPPselected] = useState(0);
  const [cantudpcuatroPPselected, setCantudpcuatroPPselected] = useState(0);
  //Se utilizan para registrar el cod 118 Embolse y especificar cinta Prematura+Presente
  const [sumcantPP, setsumCantPP] = useState(0);
  const [sumcantPm, setsumCantPm] = useState(0);
  const [sumcantPs, setsumCantPs] = useState(0);
  //cod para cinta en la labor 119 colocar guantelete
  const [cintaudpunoselected, setCintaudpunoselected] = useState(0);
  const [cintaudpdosselected, setCintaudpdosselected] = useState(0);
  const [cintaudptresselected, setCintaudptresselected] = useState(0);
  const [cintaudpcuatroselected, setCintaudpcuatroselected] = useState(0);
  //Buscar en calendario según fecha cintaPm y CintaPs que se esta trabajando
  const [ calendar, setCalendar ] = useState([]);  


  const [alert, setAlert] = useState({})    

  const navigate = useNavigate();

  const { 
    register, 
    handleSubmit,
    formState:{
        errors,
        isValid
    }  
  } = useForm({ mode: 'onChange'});    

  useEffect(() => {  
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return   
            await getRdt();                
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();              
},[auth])
//date       
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return                                                      
            //Envio a consultar calendario para cintas Pm y Ps en la fecha consultada para labor 118-Embolse Unicamente
            await getCalendar();
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();       
 },[rdt])
//Conceptos       
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return           
          conceptoSeleted? getConcepto() : console.log(''); 
          conceptoSeleted? getRdtstrabajador() : console.log('');                              
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();                     
},[conceptoSeleted])
//Udps uno     
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return
          udpunoselected ? getUdpuno() : cleanStatesudpuno();               
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();         
        
},[udpunoselected])
//Cantidad Prem y Pres Udps uno para cod 118: Embolse     
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpunoselected ? setCantudpunoPPselected( Number(cantudpunoPmselected) + Number(cantudpunoPsselected)) : cleanStatesudpuno();               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
},[cantudpunoPmselected , cantudpunoPsselected])
//Udps dos     
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return
          udpdosselected ? getUdpdos() : cleanStatesudpdos();               
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();         
        
},[udpdosselected])
//Cantidad Prem y Pres Udps dos para cod 118: Embolse    
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpdosselected ? setCantudpdosPPselected( Number(cantudpdosPmselected) + Number(cantudpdosPsselected)) : cleanStatesudpdos();               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
},[cantudpdosPmselected , cantudpdosPsselected])
//Udps tres     
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return
          udptresselected ? getUdptres() : cleanStatesudptres();               
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();                      
},[udptresselected])
//Cantidad Prem y Pres Udps tres para cod 118: Embolse    
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udptresselected ? setCantudptresPPselected( Number(cantudptresPmselected) + Number(cantudptresPsselected)) : cleanStatesudptres();               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
},[cantudptresPmselected , cantudptresPsselected])
//Udps cuatro     
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return
          udpcuatroselected ? getUdpcuatro() : cleanStatesudpcuatro();               
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();         
        
},[udpcuatroselected])
//Cantidad Prem y Pres Udps cuatro para cod 118: Embolse    
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpcuatroselected ? setCantudpcuatroPPselected( Number(cantudpcuatroPmselected) + Number(cantudpcuatroPsselected)) : cleanStatesudpcuatro();               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
},[cantudpcuatroPmselected , cantudpcuatroPsselected])
//Suma Prem para cod 118: Embolse Prematuro
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpunoselected ? await getSumcantidadPrem() : console.log('');               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
 },[cantudpunoPmselected , cantudpdosPmselected,
    cantudptresPmselected , cantudpcuatroPmselected])
//Suma Pres para cod 118: Embolse Presente
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpunoselected ? getSumcantidadPres() : console.log('');               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
 },[cantudpunoPsselected , cantudpdosPsselected,
    cantudptresPsselected , cantudpcuatroPsselected])
//Suma Prem+Pres para cod 118: Embolse Prematuro + Presente
useEffect(() => {
    const getData = async ()=>{
        try {
            const token = getToken();
            if(!token) return
            udpunoselected ? setsumCantPP( Number(sumcantPm) + Number(sumcantPs)) : console.log('');               
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();         
          
 },[sumcantPm, sumcantPs])
//Sum Cantidad     
useEffect(() => {
  const getData = async ()=>{
      try {
          const token = getToken();
          if(!token) return
          getSumcantidad();               
      } catch (error) {
          console.log(error)
      }             
  }    
  getData();         
        
},[cantudpunoselected, cantudpdosselected, 
  cantudptresselected,cantudpcuatroselected])

//Concepts
const getConcepto = async ()=>{
    //console.log({concepto: makeConceptoFilters()})
    if(rdt){
        try {            
            const response = await requestHttp(
                {
                    method: HTTP_VERBS.GET,
                    endpoint: '/conceptossucursal',                         
                    params: makeConceptoFilters()                                     
                }
            );             
            const {data} = response; 
            //console.log({datos: data})   
            data ? setConcepto(data):console.log('');                                                                                                    
        } catch (error) {
            console.log(error)
        }
        
    }else{
      console.log('');
    } 
}        
const makeConceptoFilters = ()=>{
    const filters = {};  
    if(sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES){
        filters['Ibm'] = sucursalSeleted;            
        filters['IntCodigo'] = conceptoSeleted;
    }
    //console.log(filters);         
    return filters;            
}
//Udp Uno
const getUdpuno = async ()=>{
    //console.log({filtroudpuno: makeUdpunoFilters()})
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/udps',                         
                params: makeUdpunoFilters()                                     
            }
        );             
        const {data} = response;    
        data?setUdpuno(data):setUdpuno([]);            
                                                      
    } catch (error) {
        console.log(error)
    }
}    
const makeUdpunoFilters = ()=>{
    const filters = {};  
    if(sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES){
        filters['Ibm'] = sucursalSeleted;
        filters['intClase'] = '2'; 
        filters['IntCodigo'] = udpunoselected;          
    }       
    return filters;            
}
const cleanStatesudpuno = ()=>{   
  setUdpuno([]);
}
//Udps dos
const getUdpdos = async ()=>{
  //console.log({filtroudpdos: makeUdpdosFilters()})
  try {            
      const response = await requestHttp(
          {
              method: HTTP_VERBS.GET,
              endpoint: '/udps',                         
              params: makeUdpdosFilters()                                     
          }
      );             
      const {data} = response;    
      data?setUdpdos(data):setUdpdos([]);            
                                                    
  } catch (error) {
      console.log(error)
  }
}
const makeUdpdosFilters = ()=>{
  const filters = {};  
  if(sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES){
      filters['Ibm'] = sucursalSeleted;
      filters['intClase'] = '2'; 
      filters['IntCodigo'] = udpdosselected;          
  }       
  return filters;            
}
//Udps tres
const getUdptres = async ()=>{
  //console.log({filtroudptres: makeUdptresFilters()})
  try {            
      const response = await requestHttp(
          {
              method: HTTP_VERBS.GET,
              endpoint: '/udps',                         
              params: makeUdptresFilters()                                     
          }
      );             
      const {data} = response;    
      data?setUdptres(data):setUdptres([]);            
                                                    
  } catch (error) {
      console.log(error)
  }
}
const makeUdptresFilters = ()=>{
  const filters = {};  
  if(sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES){
      filters['Ibm'] = sucursalSeleted;
      filters['intClase'] = '2'; 
      filters['IntCodigo'] = udptresselected;          
  }       
  return filters;            
}
//Udps cuatro
const getUdpcuatro = async ()=>{
  //console.log({filtroudpcuatro: makeUdpcuatroFilters()})
  try {            
      const response = await requestHttp(
          {
              method: HTTP_VERBS.GET,
              endpoint: '/udps',                         
              params: makeUdpcuatroFilters()                                     
          }
      );             
      const {data} = response;    
      data?setUdpcuatro(data):setUdpcuatro([]);            
                                                    
  } catch (error) {
      console.log(error)
  }
}
const makeUdpcuatroFilters = ()=>{
  const filters = {};  
  if(sucursalSeleted !== ALL_SUCURSALES && conceptoSeleted !== ALL_SUCURSALES){
      filters['Ibm'] = sucursalSeleted;
      filters['intClase'] = '2'; 
      filters['IntCodigo'] = udpcuatroselected;          
  }       
  return filters;            
}
//Cantidad
const getSumcantidad = async ()=>{                   
  setSumcantidad( Number(cantudpunoselected)  + Number(cantudpdosselected) +
                  Number(cantudptresselected) + Number(cantudpcuatroselected));                               
}
//Suma Prem para cod 118: Embolse Prematuro
const getSumcantidadPrem = async ()=>{                   
    setsumCantPm( Number(cantudpunoPmselected) + Number(cantudpdosPmselected) + 
                  Number(cantudptresPmselected) + Number(cantudpcuatroPmselected))                               
}
//Suma Pres para cod 118: Embolse Presente
const getSumcantidadPres = async ()=>{                   
    setsumCantPs( Number(cantudpunoPsselected) + Number(cantudpdosPsselected) + 
                  Number(cantudptresPsselected) + Number(cantudpcuatroPsselected))                               
}
//Clean states uds uno
const cleanStatesudpunoReset = ()=>{       
  setUdpunoselected(0);
  setCantudpunoselected(0);
  setCantudpunoPmselected(0);
  setCantudpunoPsselected(0);
  setCantudpunoPPselected(0);
  setCintaudpunoselected(0);
}
//Clean states uds dos
const cleanStatesudpdos = ()=>{       
  setUdpdos([]);  
}
const cleanStatesudpdosReset = ()=>{       
  setUdpdosselected(0);
  setCantudpdosselected(0);
  setCantudpdosPmselected(0);
  setCantudpdosPsselected(0);
  setCantudpdosPPselected(0);
  setCintaudpdosselected(0);
}
//Clean states uds tres
const cleanStatesudptres = ()=>{  
  setUdptres([]);
}
const cleanStatesudptresReset = ()=>{       
  setUdptresselected(0);
  setCantudptresselected(0);
  setCantudptresPmselected(0);
  setCantudptresPsselected(0);
  setCantudptresPPselected(0);
  setCintaudptresselected(0);
}
//Clean states udp cuatro
const cleanStatesudpcuatro = ()=>{  
  setUdpcuatro([]);
}
const cleanStatesudpcuatroReset = ()=>{       
  setUdpcuatroselected(0);
  setCantudpcuatroselected(0);
  setCantudpcuatroPmselected(0);
  setCantudpcuatroPsselected(0);
  setCantudpcuatroPPselected(0);
  setCintaudpcuatroselected(0);
}
//Close session if token expired
const hendleCloseSeccion = async ()=>{
  closeSeccionAuth()
  removeToken()
  showAlert(
      'Inicie Seccion Nuevamente', 
      "Su token puede estar vencido", 
      SW_ICON.INFO,
      () => { navigate('/Login')}    
  );
}
//RdtById
const getRdt = async ()=>{
     
  try {                      
     const response = await requestHttp(
        {
              method: HTTP_VERBS.GET,
              endpoint: `/rdts/${id}`                                                                       
        }
     );             
     const {data} = response;
     //console.log({Data: data})                
     data?setRdt(data):console.log(''); 
     data?setConceptoSelected(data.IntCodigo_CONC):console.log('')  
     data?setSucursalSelected(data.IbmLaboro):console.log('')  
     data?setSumcantidad(data.intTotal_RDT):console.log('')  
     data?setHoras(data.intHora_RDT):console.log('')  
     data?setObservacion(data.strObservacion_RDT):console.log('')  
     data?setDate(data.dtmFecha_RDT):console.log('')  

     data.IntCodigo_UDP?setUdpunoselected(data.IntCodigo_UDP):console.log('')  
     data.IntCodigo_UDP?setCantudpunoselected(data.intCantidad_UDP):console.log('')  
     data.IntCodigo_UDPdos?setUdpdosselected(data.IntCodigo_UDPdos):console.log('')  
     data.IntCodigo_UDPdos?setCantudpdosselected(data.intCantidad_UDPdos):console.log('')  
     data.IntCodigo_UDPtres?setUdptresselected(data.IntCodigo_UDPtres):console.log('')  
     data.IntCodigo_UDPtres?setCantudptresselected(data.intCantidad_UDPtres):console.log('')  
     data.IntCodigo_UDPcuatro?setUdpcuatroselected(data.IntCodigo_UDPcuatro):console.log('')  
     data.IntCodigo_UDPcuatro?setCantudpcuatroselected(data.intCantidad_UDPcuatro):console.log('')
     
     //118-Embolse Unicamente
     if(data.IntCodigo_CONC === '118'){
        //console.log({pmUno: data.strUdp[0]?data.strUdp[0].intCantidad_UDP:0})
        data.strUdp[0]?setCantudpunoPmselected(data.strUdp[0].intCantidad_UDP):console.log('')
        data.strUdp[1]?setCantudpunoPsselected(data.strUdp[1].intCantidad_UDP):console.log('')
        data.strUdp[2]?setCantudpdosPmselected(data.strUdp[2].intCantidad_UDP):console.log('')
        data.strUdp[3]?setCantudpdosPsselected(data.strUdp[3].intCantidad_UDP):console.log('')
        data.strUdp[4]?setCantudptresPmselected(data.strUdp[4].intCantidad_UDP):console.log('')
        data.strUdp[5]?setCantudptresPsselected(data.strUdp[5].intCantidad_UDP):console.log('')
        data.strUdp[6]?setCantudpcuatroPmselected(data.strUdp[6].intCantidad_UDP):console.log('')
        data.strUdp[7]?setCantudpcuatroPsselected(data.strUdp[7].intCantidad_UDP):console.log('')

     }

     if(data.IntCodigo_CONC === '119' || data.IntCodigo_CONC === '451'){
        data.strUdp[0]?setCintaudpunoselected(data.strUdp[0].idCinta_UDP):console.log('')
        data.strUdp[1]?setCintaudpdosselected(data.strUdp[1].idCinta_UDP):console.log('')
        data.strUdp[2]?setCintaudptresselected(data.strUdp[2].idCinta_UDP):console.log('')
        data.strUdp[3]?setCintaudpcuatroselected(data.strUdp[3].idCinta_UDP):console.log('')        
     }
     
     if(data.IbmLaboro === auth.data.ibm && auth.data.role != 1){
        const rdtData = {
            ibm: data.IbmLaboro,
            dateRdt: data.dtmFecha_RDT
        };
        data?setRdtprov(rdtData):null()  
        //console.log({rdtData: rdtData})
     }else{
        const rdtData = {
            ibm: auth.data.ibm,
            dateRdt: data.dtmFecha_RDT
        };
        data?setRdtprov(rdtData):console.log('')  
        //console.log({rdtData: rdtData})
     }          
          
  } catch (error) {
       if(error.response.status === 401){
           hendleCloseSeccion();
       }else{
           console.log(error)
       } 
  }

}
//getRdts
const getRdtstrabajador = async ()=>{
  if(rdt){
      try {                      
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.GET,
                  endpoint: '/Rdts',                         
                  params: makePropertiesFilterstrabajador()                                     
              }
          );             
          const {data} = response;  
          data ? setRdtstrabajador(data):console.log('');            

      } catch (error) {
          if(error.response.status === 401){
              hendleCloseSeccion();
          }else{
              console.log(error)
          }
      }
  }else{
      setRdtstrabajador([])
  }    
      
}
const makePropertiesFilterstrabajador = ()=>{
  const filters = {};                
        filters['Fecha_INI'] = rdt? rdt.dtmFecha_RDT:''; 
        filters['Fecha_FIN'] = rdt? rdt.dtmFecha_RDT:'';
        filters['IntCodigo_EMPL'] = rdt? rdt.IntCodigo_EMPL:'';
        filters['strCodigo_SUCU'] = rdt? rdt.strCodigo_SUCU:'';                    
        //console.log({filtrotrab: filters});
  return filters;       
}
//getCalendars para prematuro y presente 118-Embolse Unicamente
const getCalendar = async ()=>{                
    try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/calendars',                         
                params: makePropertiesFiltersCalendar()                                     
            }
        );             
        const {data} = response;                
        setCalendar(data); 
        //console.log(calendar);       
        //console.log(calendar[0].cintaPm);    
        //console.log(calendar[0].cintaPs);                 
    } catch (error) {
        if(error.response.status === 401){
            hendleCloseSeccion();
        }else{
            console.log(error)
        }
    }    
}
const makePropertiesFiltersCalendar = ()=>{
    const filters = {};                
        filters['Fecha'] = date.split('T')[0];             
        
        //filters['employeesId'] = auth.data._id;              
       //console.log({filters: filters});
    return filters;       
}
//Update
const onSubmitsave = async (data) =>{
        //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede realizar registros de RDT para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar de lunes a Jueves
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return 
            }
        }    
  //Validaciones
  let sumaH = 0;
  //Valido Fecha
  if(fecha > hoy){
      setAlert({
          msg: 'Fecha no puede ser "mayor" a la fecha actual',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
  }
  //Empleado
  if(!rdt.IntCodigo_EMPL){
      setAlert({
          msg: 'Se requiere codigo del trabajador',
          error: true
      }) 
      //console.log({mensajeincludes: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return    
  }
  //Sumar horas reportadas al trabajador
  if(rdtstrabajador[0]){            
      const lista = rdtstrabajador.map(item => ({
          sumaHoras: Math.floor(item.intHora_RDT)
         }));                                   
         sumaH = lista.reduce((prev, next) => prev + next.sumaHoras, 0);

         //console.log({lista: lista});   
         //console.log({horas: sumaH})
  } 
  //Cod Labor      
  if(!rdt.IntCodigo_CONC){
      setAlert({
          msg: 'Se requiere codigo de la labor realizada',
          error: true
      }) 
      //console.log({mensajeincludes: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return    
  }
  //Exige Udp        
  if(concepto.intEXUDP === 1){
      if(concepto.strUMedida === 'Hectarea'){   
          //udp1                        
          if(!udpuno[0]){
              setAlert({
                  msg: 'Necesita digitar la UDP1',
                  error: true
              })            
              setTimeout(()=>{
                  setAlert({})
              }, 10000)            
              return
          }                        
          if(Number(cantudpunoselected) <= 0 ){
              setAlert({
                  msg: 'Necesita digitar una cantidad para la "UDP1"',
                  error: true
              })            
              setTimeout(()=>{
                  setAlert({})
              }, 10000)            
              return
          }
          //Si estoy registrando Manejo Cobertura, Manejo cobertura contacto, Fumigacion Contacto, Fumigacion Herbicida Sistemico cant permitidad hasta su Area Bruta                
          if(concepto.IntCodigo === '143' || concepto.IntCodigo === '142' || concepto.IntCodigo === '137' || concepto.IntCodigo === '137'|| concepto.IntCodigo === '155'){
              if(Number(cantudpunoselected) > udpuno[0].intABruta){
                  setAlert({
                      msg: 'Cantidad para "UDP1" no puede ser mayor a su "Area Bruta"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
          }else{
              if(Number(cantudpunoselected) > udpuno[0].intANeta){
                  setAlert({
                      msg: 'Cantidad para "UDP1" no puede ser mayor a su "Area Neta"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
          }  
          //udp2                      
          if(udpdos[0]){                    
              if(Number(cantudpdosselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP2"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 7000)            
                  return
              }
              if(concepto.IntCodigo === '143' || concepto.IntCodigo === '142'){
                  if(Number(cantudpdosselected) > udpdos[0].intABruta){
                      setAlert({
                          msg: 'Cantidad para "UDP2" no puede ser mayor a su "Area Bruta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }else{
                  if(Number(cantudpdosselected) > udpdos[0].intANeta){
                      setAlert({
                          msg: 'Cantidad para "UDP2" no puede ser mayor a su "Area Neta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }                    
          }
          //udp3
          if(udptres[0]){
              if(Number(cantudptresselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP3"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
              if(concepto.IntCodigo === '143' || concepto.IntCodigo === '142'){
                  if(Number(cantudptresselected) > udptres[0].intABruta){
                      setAlert({
                          msg: 'Cantidad para "UDP3" no puede ser mayor a su "Area Bruta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }else{
                  if(Number(cantudptresselected) > udptres[0].intANeta){
                      setAlert({
                          msg: 'Cantidad para "UDP3" no puede ser mayor a su "Area Neta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }                    
          }   
          if(udpcuatro[0]){
              if(Number(cantudpcuatroselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP4"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
              if(concepto.IntCodigo === '143' || concepto.IntCodigo === '142'){
                  if(Number(cantudpcuatroselected) > udpcuatro[0].intABruta){
                      setAlert({
                          msg: 'Cantidad para "UDP4" no puede ser mayor a su "Area Bruta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }else{
                  if(Number(cantudpcuatroselected) > udpcuatro[0].intANeta){
                      setAlert({
                          msg: 'Cantidad para "UDP4" no puede ser mayor a su "Area Neta"',
                          error: true
                      })            
                      setTimeout(()=>{
                          setAlert({})
                      }, 10000)            
                      return
                  }
              }                    
          }
      }else{
          if(!udpuno[0]){
              setAlert({
                  msg: 'Necesita digitar la UDP1',
                  error: true
              })            
              setTimeout(()=>{
                  setAlert({})
              }, 10000)            
              return
          }                        
          if(Number(cantudpunoselected) <= 0 ){
              setAlert({
                  msg: 'Necesita digitar una cantidad para la "UDP1"',
                  error: true
              })            
              setTimeout(()=>{
                  setAlert({})
              }, 10000)            
              return
          }                        
          if(udpdos[0]){
              if(Number(cantudpdosselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP2"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }

          }
          if(udptres[0]){
              if(Number(cantudptresselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP3"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
          }   
          if(udpcuatro[0]){
              if(Number(cantudpcuatroselected) <= 0 ){
                  setAlert({
                      msg: 'Necesita digitar una cantidad para la "UDP4"',
                      error: true
                  })            
                  setTimeout(()=>{
                      setAlert({})
                  }, 10000)            
                  return
              }
          }
      }        
         
  }       
  //Horas
  //Viernes
  if(dias[numeroDia] === 'VIERNES'){  
      const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas);
      //console.log({acumuladoHoras: acumHoras})
      if(Number(acumHoras) > Number(8)){
          setAlert({
              msg: 'Horas no pueden superar el max de "8 horas" para el trabajador en el día',
              error: true
          })
          console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return   
      }           
  } 
  //Sabado y Domingo
  if(dias[numeroDia] === 'SABADO' || dias[numeroDia] === 'DOMINGO'){
      if(Number(horas) > Number(0)){
          setAlert({
              msg: 'Hora debe ser "0 horas" para el trabajador los dias sabado ó domingo',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return   
      }
  }
  //Lunes-Jueves       
  const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas);
  //console.log({horas: horas})
  if(Number(acumHoras) > Number(HORA)){
      setAlert({
          msg: 'Horas no pueden superar el max de "9.5 horas" para el trabajador en el día',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return   
  }
  //Total
  if(Number(sumcantidad) <= 0 || sumcantidad === ''){
      setAlert({
          msg: 'Cantidad total no puede ser igual a cero "0"',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return   
  }
  //Finca Laboro
  let FincaLaboro = '';
  if(sucursalSeleted === '20175'){
      FincaLaboro = 'FINCA ASTURIAS'
  }else if(sucursalSeleted === '20180'){
      FincaLaboro = 'FINCA TOSCANA'
  }else if(sucursalSeleted === '20186'){
      FincaLaboro = 'FINCA ARIZONA'
  }else if(sucursalSeleted === '80132'){
      FincaLaboro = 'FINCA AGUA VERDE'
  }else if(sucursalSeleted === '20188'){
      FincaLaboro = 'FINCA ALCATRAZ'
  }else if(sucursalSeleted === '20947'){
      FincaLaboro = 'FINCA BUENOS AIRES'
  }else if(sucursalSeleted === '20128'){
      FincaLaboro = 'FINCA EDEN'
  }else if(sucursalSeleted === '20065'){
      FincaLaboro = 'FINCA JUANCA'
  }else if(sucursalSeleted === '23270'){
      FincaLaboro = 'FINCA TIKAL'
  }else if(sucursalSeleted === '80108'){
      FincaLaboro = 'FINCA LA MONICA'
  }else if(sucursalSeleted === '20185'){
      FincaLaboro = 'FINCA LA CEJA'
  }else if(sucursalSeleted === '20194'){
      FincaLaboro = 'FINCA MANAURE'
  }
  setAlert({});                 
  try {
      const fecha = Date.now();            
      const dataRdt = {};
      //dataRdt['IntCodigo_EMPL'] = employe[0].IntCodigo;
      //dataRdt['strDocumento'] = employe[0].strDocumento;
      //dataRdt['strNombreTrabajador'] = employe[0].strNombreTrabajador;
      //dataRdt['strCodigo_SUCU'] = employe[0].Ibm;
      //dataRdt['strNombre_SUCU'] = employe[0].strCodigoCentro;
      //dataRdt['strNombre_EMPR'] = employe[0].Contratista;
      //dataRdt['IntCodigo_CONC'] = concepto.IntCodigo;
      //dataRdt['strConcepto'] = concepto.strConcepto;
      if(Number(horas) > Number(HORA)){
        setAlert({
            msg: 'Horas no pueden superar el max de "9.5 horas" para el trabajador en el día',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return   
      }else{
        dataRdt['intHora_RDT'] = horas; 
      }
           
      dataRdt['intTotal_RDT'] = sumcantidad;
      //console.log({dataTotal: sumcantidad})
      //dataRdt['dtmFecha_RDT'] = data.fechardt;
      //dataRdt['IbmLaboro'] = sucursalSeleted;
      //dataRdt['strFincaLaboro'] = FincaLaboro;
      dataRdt['strObservacion_RDT'] = observacion;      
      //dataRdt['email_USUA'] = auth.data.email;
      //dataRdt['dtmFechaCrea'] = fecha;
      dataRdt['dtmFechaModifica'] = fecha;
      //dataRdt['employeesId'] = auth.data._id;

      dataRdt['IntCodigo_UDP'] = udpuno[0]?udpuno[0].IntCodigo:'';            
      dataRdt['strNombre_UDP'] = udpuno[0]?udpuno[0].strDescripcion:'';
      dataRdt['intCantidad_UDP'] = udpuno[0]?cantudpunoselected:'';

      dataRdt['IntCodigo_UDPdos'] = udpdos[0]?udpdos[0].IntCodigo:'';            
      dataRdt['strNombre_UDPdos'] = udpdos[0]?udpdos[0].strDescripcion:'';
      dataRdt['intCantidad_UDPdos'] = udpdos[0]?cantudpdosselected:'';

      dataRdt['IntCodigo_UDPtres'] = udptres[0]?udptres[0].IntCodigo:'';            
      dataRdt['strNombre_UDPtres'] = udptres[0]?udptres[0].strDescripcion:'';
      dataRdt['intCantidad_UDPtres'] = udptres[0]?cantudptresselected:'';

      dataRdt['IntCodigo_UDPcuatro'] = udpcuatro[0]?udpcuatro[0].IntCodigo:'';            
      dataRdt['strNombre_UDPcuatro'] = udpcuatro[0]?udpcuatro[0].strDescripcion:'';
      dataRdt['intCantidad_UDPcuatro'] = udpcuatro[0]?cantudpcuatroselected:'';

      dataRdt['intTotal_RDT'] = sumcantidad;
      
      //Embolse            
      if(concepto.IntCodigo == 118){
            dataRdt['strUdp'] = [
                {"IntCodigo_UDP": udpuno[0]?udpuno[0].IntCodigo:'', "strNombre_UDP": udpuno[0]?udpuno[0].strDescripcion:'', "strCinta_UDP": udpuno[0]?nameCintaPm:'', "intCantidad_UDP": udpuno[0]?cantudpunoPmselected:'', "strTipoCinta_UDP": udpuno[0]?'PM':'', "intCodTipo_UDP": udpuno[0]?1:'', "intCantidadTotal_UDP": udpuno[0]?cantudpunoselected:''},
                {"IntCodigo_UDP": udpuno[0]?udpuno[0].IntCodigo:'', "strNombre_UDP": udpuno[0]?udpuno[0].strDescripcion:'', "strCinta_UDP": udpuno[0]?nameCintaPs:'', "intCantidad_UDP": udpuno[0]?cantudpunoPsselected:'', "strTipoCinta_UDP": udpuno[0]?'PS':'', "intCodTipo_UDP": udpuno[0]?2:'', "intCantidadTotal_UDP": udpuno[0]?0:''},
                {"IntCodigo_UDP": udpdos[0]?udpdos[0].IntCodigo:'', "strNombre_UDP": udpdos[0]?udpdos[0].strDescripcion:'', "strCinta_UDP": udpdos[0]?nameCintaPm:'', "intCantidad_UDP": udpdos[0]?cantudpdosPmselected:'', "strTipoCinta_UDP": udpdos[0]?'PM':'', "intCodTipo_UDP": udpdos[0]?1:'', "intCantidadTotal_UDP": udpdos[0]?cantudpdosselected:''},
                {"IntCodigo_UDP": udpdos[0]?udpdos[0].IntCodigo:'', "strNombre_UDP": udpdos[0]?udpdos[0].strDescripcion:'', "strCinta_UDP": udpdos[0]?nameCintaPs:'', "intCantidad_UDP": udpdos[0]?cantudpdosPsselected:'', "strTipoCinta_UDP": udpdos[0]?'PS':'', "intCodTipo_UDP": udpdos[0]?2:'', "intCantidadTotal_UDP": udpdos[0]?0:''},
                {"IntCodigo_UDP": udptres[0]?udptres[0].IntCodigo:'', "strNombre_UDP": udptres[0]?udptres[0].strDescripcion:'', "strCinta_UDP": udptres[0]?nameCintaPm:'', "intCantidad_UDP": udptres[0]?cantudptresPmselected:'', "strTipoCinta_UDP": udptres[0]?'PM':'', "intCodTipo_UDP": udptres[0]?1:'', "intCantidadTotal_UDP": udptres[0]?cantudptresselected:''},
                {"IntCodigo_UDP": udptres[0]?udptres[0].IntCodigo:'', "strNombre_UDP": udptres[0]?udptres[0].strDescripcion:'', "strCinta_UDP": udptres[0]?nameCintaPs:'', "intCantidad_UDP": udptres[0]?cantudptresPsselected:'', "strTipoCinta_UDP": udptres[0]?'PS':'', "intCodTipo_UDP": udptres[0]?2:'', "intCantidadTotal_UDP": udptres[0]?0:''},
                {"IntCodigo_UDP": udpcuatro[0]?udpcuatro[0].IntCodigo:'', "strNombre_UDP": udpcuatro[0]?udpcuatro[0].strDescripcion:'', "strCinta_UDP": udpcuatro[0]?nameCintaPm:'', "intCantidad_UDP": udpcuatro[0]?cantudpcuatroPmselected:'', "strTipoCinta_UDP": udpcuatro[0]?'PM':'', "intCodTipo_UDP": udpcuatro[0]?1:'', "intCantidadTotal_UDP": udpcuatro[0]?cantudpcuatroselected:''},
                {"IntCodigo_UDP": udpcuatro[0]?udpcuatro[0].IntCodigo:'', "strNombre_UDP": udpcuatro[0]?udpcuatro[0].strDescripcion:'', "strCinta_UDP": udpcuatro[0]?nameCintaPs:'', "intCantidad_UDP": udpcuatro[0]?cantudpcuatroPsselected:'', "strTipoCinta_UDP": udpcuatro[0]?'PS':'', "intCodTipo_UDP": udpcuatro[0]?2:'', "intCantidadTotal_UDP": udpcuatro[0]?0:''}
            ]
      }      
       //Colocar Guantelete
       if(concepto.IntCodigo == 119 || concepto.IntCodigo == 451){
        dataRdt['strUdp'] = [
            {"IntCodigo_UDP": udpuno[0]?udpuno[0].IntCodigo:'', "strNombre_UDP": udpuno[0]?udpuno[0].strDescripcion:'', "idCinta_UDP": udpuno[0]?cintaudpunoselected:'', "strCinta_UDP": udpuno[0]?nameCinta:'', "intCantidadTotal_UDP": udpuno[0]?cantudpunoselected:''},
            {"IntCodigo_UDP": udpdos[0]?udpdos[0].IntCodigo:'', "strNombre_UDP": udpdos[0]?udpdos[0].strDescripcion:'', "idCinta_UDP": udpuno[0]?cintaudpdosselected:'', "strCinta_UDP": udpdos[0]?nameCintados:'', "intCantidadTotal_UDP": udpdos[0]?cantudpdosselected:''},
            {"IntCodigo_UDP": udptres[0]?udptres[0].IntCodigo:'', "strNombre_UDP": udptres[0]?udptres[0].strDescripcion:'', "idCinta_UDP": udpuno[0]?cintaudptresselected:'', "strCinta_UDP": udptres[0]?nameCintatres:'', "intCantidadTotal_UDP": udptres[0]?cantudptresselected:''},
            {"IntCodigo_UDP": udpcuatro[0]?udpcuatro[0].IntCodigo:'', "strNombre_UDP": udpcuatro[0]?udpcuatro[0].strDescripcion:'', "idCinta_UDP": udpuno[0]?cintaudpcuatroselected:'', "strCinta_UDP": udpcuatro[0]?nameCintacuatro:'', "intCantidadTotal_UDP": udpcuatro[0]?cantudpcuatroselected:''}
        ]
    }
      //console.log({datosRDT: dataRdt})
      //console.log({Fecha: fecha})

      const response = await requestHttp(
          {
            method: HTTP_VERBS.PUT,
            endpoint: `/rdts/${id}`,
            body: dataRdt,
            token: getToken()
          }
      );            
      /*setAlert({
          msg: response.data,
          error: false
      })*/
      await getRdt();
      showAlert(
          'Actualizado', 
          "Correctamente", 
          SW_ICON.SUCCESS,   
          () => { navigate('/addrdts')}               
      );
      
      /*setTimeout(()=>{
          setAlert({})                
      }, 7000)*/           
                                 
  } catch (error) {
      //console.log(error)
      setAlert({
          msg: error.response.data,
          error: true
      })
      setTimeout(()=>{
          setAlert({})                
      }, 10000) 
  }     
      
}
//focus
function FocusOnInput(){
  document.getElementById("input1").focus();
}
// Colección de inputs con índices de (0 a 3)
    // 0 es la posición de #input1, 3 es la posición de #input4
    let inputs = document.querySelectorAll('[id^="input"]');
    // Asignar evento para ejecutar función
    inputs.forEach(input => input.addEventListener('keypress', nextFocus));

    // Recibe evento como parámetro
    function nextFocus(e) {
        if(e.keyCode == 13){
            // e.target es el elemento que lanzó el evento
            // Obtener número desde ID, eliminando la palabra input (1 a 4)
            let actual = parseInt(e.target.id.replace('input', ''));
            // Solo si no es el último
            if(actual < inputs.length) {
                // Evitar comportamiento normal del evento
                e.preventDefault();
                // Activar siguiente campo
                inputs[actual].focus();
                console.log({actual: actual})                
            }
        }
    }
//Validar dia de la semana
const fechaComoCadena = date.split('T')[0] +  " 00:01:59"; // día semana
const dias = [
    'DOMINGO',
    'LUNES',
    'MARTES',
    'MIERCOLES',
    'JUEVES',
    'VIERNES',
    'SABADO',
];
const numeroDia = new Date(fechaComoCadena).getDay();
const nombreDia = dias[numeroDia];

const fecha = new Date(fechaComoCadena);
//hoy
const tiempoTranscurrido = Date.now();
const hoy = new Date(tiempoTranscurrido);

const rango = new Date(hoy - fecha);
const tiempo = new Date(189999999);

//console.log({rango: rango})
//console.log({tiempo: tiempo})
//console.log("Fecha actual: ", hoy); 
//console.log("Calendario: ", fecha); 
//console.log("NoDia: ", numeroDia);    
//console.log("nombre dia: ", nombreDia); 

//Recuperar color de cinta para labor 118 embolsePm
if(calendar.length){
    const color = colors.find(color => color.id == calendar[0].cintaPm)
    if(color){
      cintaPm = color.cinta
      nameCintaPm = color.name
      //console.log(cintaPm)
    }else{
        cintaPm=0
        nameCintaPm=''
    }
    //console.log(color)        
}else{
    cintaPm=0
    nameCintaPm=''
}
//Recuperar color de cinta para labor 118 embolsePs
if(calendar.length){
    const color = colors.find(color => color.id == calendar[0].cintaPs)
    if(color){
      cintaPs = color.cinta
      nameCintaPs = color.name
      //console.log(cintaPm)
    }else{
        cintaPs=0
        nameCintaPs=''
    }
    //console.log(color)        
}else{
    cintaPs=0
    nameCintaPs=''
}
//Recuperar color de cinta para labor 119 colocar guantelete udpuno
if(cintaudpunoselected > 0){
    const color = colors.find(color => color.id == cintaudpunoselected)
    if(color){
      cinta = color.cinta
      nameCinta = color.name
      nameCintaCorto = color.nameCorto
      //console.log(cinta)
    }else{
        cinta='transparent'
        nameCinta=''
        nameCintaCorto = ''
    }
    //console.log(color)        
}else{
    cinta='transparent'
    nameCinta=''
    nameCintaCorto = ''
}
//Recuperar color de cinta para labor 119 colocar guantelete udpdos
if(cintaudpdosselected > 0){
    const color = colors.find(color => color.id == cintaudpdosselected)
    if(color){
      cintados = color.cinta
      nameCintados = color.name
      nameCintadosCorto = color.nameCorto
      //console.log(cintados)
    }else{
        cintados='transparent'
        nameCintados=''
        nameCintadosCorto=''

    }
    //console.log(color)        
}else{
    cintados='transparent'
    nameCintados=''
    nameCintadosCorto = ''
}
//Recuperar color de cinta para labor 119 colocar guantelete udptres
if(cintaudptresselected > 0){
    const color = colors.find(color => color.id == cintaudptresselected)
    if(color){
      cintatres = color.cinta
      nameCintatres = color.name
      nameCintatresCorto = color.nameCorto
      //console.log(cintados)
    }else{
        cintatres='transparent'
        nameCintatres=''
        nameCintatresCorto=''
    }
    //console.log(color)        
}else{
    cintatres='transparent'
    nameCintatres=''
    nameCintatresCorto=''
}
//Recuperar color de cinta para labor 119 colocar guantelete udpcuatro
if(cintaudpcuatroselected > 0){
    const color = colors.find(color => color.id == cintaudpcuatroselected)
    if(color){
      cintacuatro = color.cinta
      nameCintacuatro = color.name
      nameCintacuatroCorto = color.nameCorto
      //console.log(cintados)
    }else{
        cintacuatro='transparent'
        nameCintacuatro=''
        nameCintacuatroCorto=''
    }
    //console.log(color)        
}else{
    cintacuatro='transparent'
    nameCintacuatro=''
    nameCintacuatroCorto=''
}

//Delete RDT
const showAlertYN = () =>{
  //Vallido que si este dentro de un rango permitido para eliminar
    //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede eliminar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede eliminar registros de RDT para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede eliminar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }
  swal({
      title: "Esta usted seguro?",
      text: "Una vez eliminado, no podras recuperar este registro!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {   
          rdtDelete()         
       /*   swal("El registro ha sido eliminado correctamente!", {
          icon: "success",
                      
        });*/           

      } else {
        //swal("Su registro esta a salvo!");
        return
      }
      //console.log(willDelete)
    });
}
const rdtDelete = async () =>{             
  try {           
      const response = await requestHttp(
          {
              method: HTTP_VERBS.DELETE,
              endpoint: `/rdts/${id}`,               
              token: getToken()               
          }
      );   
      showAlert(
          'RDT', 
          "Eliminado Correctamente", 
          SW_ICON.SUCCESS,   
          () => { navigate('/addrdts')}                 
      );                              
          
      } catch (error) { 
          if(error.response.status === 401){
              hendleCloseSeccion();
          }else{
              showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
          }            
      }      
 
}
//Update RDT All Labor
const showAlertYNUpdateAll = () =>{
    //Vallido que si este dentro de un rango permitido para Actualizar
    //Validaciones
        //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede actualizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede actualizar registros de RDT para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede actualizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return 
            }
        }
    //Solo se permite actualizar labores de embarque "Caja"
    if(concepto.strUMedida != "Caja"){
        setAlert({
            msg: 'Solo es permitido para labores de embarque, con Unidad de Medidad "Cajas"',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return
  }

    swal({
        title: "Actualizar!!! Hora, Cantidad y Observación",
        text: "para todos los registros de este codigo, para este dia y finca!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willUpdate) => {
        if (willUpdate) {   
            onSubmitUdateAll()         
         /*   swal("los registros se han actualizado correctamente!", {
            icon: "success",
                        
          });*/           
  
        } else {
          //swal("Su registro esta a salvo!");
          return
        }
        //console.log(willDelete)
      });
} 
//Update ALL
const onSubmitUdateAll = async (data) =>{
    //Vallido que si este dentro de un rango permitido para registrar
        //valido si dia es viernes o sabado                          
        if(numeroDia >= 5){
            //rango para el viernes de 4 dias.
            if(numeroDia == 5){                
                if(rango > 363599999){
                    setAlert({
                        msg: 'Usted ya no puede realizar registros de RDT para este dia',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
            }else{
                //rango para el sabado de 3 dias.                     
                    if(rango > 276999999){
                    //if(rango > 363599999){ //Festivo dia lunes
                        setAlert({
                            msg: 'Usted ya no puede realizar registros de RDT para este dia',
                            error: true
                        })
                        //console.log({mensaje: alert.msg}); 
                        setTimeout(()=>{
                            setAlert({})
                        }, 7000)            
                        return
                    }
            }
        }else{
            //Rango de 2 dias para registrar
            if(rango > 189999999){
            //if(rango > 276999999){
                setAlert({
                    msg: 'Usted ya no puede realizar registros de RDT para este dia',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return 
            }
        } 
    //Validaciones
    let sumaH = 0;
    //Valido Fecha
    if(fecha > hoy){
        setAlert({
            msg: 'Fecha no puede ser "mayor" a la fecha actual',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return     
    }
    //Empleado
    if(!rdt.IntCodigo_EMPL){
        setAlert({
            msg: 'Se requiere codigo del trabajador',
            error: true
        }) 
        //console.log({mensajeincludes: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return    
    }
    //Sumar horas reportadas al trabajador
    if(rdtstrabajador[0]){            
        const lista = rdtstrabajador.map(item => ({
            sumaHoras: Math.floor(item.intHora_RDT)
           }));                                   
           sumaH = lista.reduce((prev, next) => prev + next.sumaHoras, 0);
  
           //console.log({lista: lista});   
           //console.log({horas: sumaH})
    } 
    //Cod Labor      
    if(!rdt.IntCodigo_CONC){
        setAlert({
            msg: 'Se requiere codigo de la labor realizada',
            error: true
        }) 
        //console.log({mensajeincludes: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return    
    }         
    //Horas
    //Viernes
    if(dias[numeroDia] === 'VIERNES'){  
        const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas);
        //console.log({acumuladoHoras: acumHoras})
        if(Number(acumHoras) > Number(8)){
            setAlert({
                msg: 'Horas no pueden superar el max de "8 horas" para el trabajador en el día',
                error: true
            })
            console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return   
        }           
    } 
    //Sabado y Domingo
    if(dias[numeroDia] === 'SABADO' || dias[numeroDia] === 'DOMINGO'){
        if(Number(horas) > Number(0)){
            setAlert({
                msg: 'Hora debe ser "0 horas" para el trabajador los dias sabado ó domingo',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return   
        }
    }
    //Lunes-Jueves       
    const acumHoras = (Number(sumaH) - Number(rdt.intHora_RDT)) + Number(horas);
    //console.log({horas: horas})
    if(Number(acumHoras) > Number(HORA)){
        setAlert({
            msg: 'Horas no pueden superar el max de "9.75 horas" para el trabajador en el día',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return   
    }
    //Total
    if(Number(sumcantidad) <= 0 || sumcantidad === ''){
        setAlert({
            msg: 'Cantidad total no puede ser igual a cero "0"',
            error: true
        })
        //console.log({mensaje: alert.msg}); 
        setTimeout(()=>{
            setAlert({})
        }, 7000)            
        return   
    }
    //Finca Laboro
    let FincaLaboro = '';
    if(sucursalSeleted === '20175'){
        FincaLaboro = 'FINCA ASTURIAS'
    }else if(sucursalSeleted === '20180'){
        FincaLaboro = 'FINCA TOSCANA'
    }else if(sucursalSeleted === '20186'){
        FincaLaboro = 'FINCA ARIZONA'
    }else if(sucursalSeleted === '80132'){
        FincaLaboro = 'FINCA AGUA VERDE'
    }else if(sucursalSeleted === '20188'){
        FincaLaboro = 'FINCA ALCATRAZ'
    }else if(sucursalSeleted === '20947'){
        FincaLaboro = 'FINCA BUENOS AIRES'
    }else if(sucursalSeleted === '20128'){
        FincaLaboro = 'FINCA EDEN'
    }else if(sucursalSeleted === '20065'){
        FincaLaboro = 'FINCA JUANCA'
    }else if(sucursalSeleted === '23270'){
        FincaLaboro = 'FINCA TIKAL'
    }else if(sucursalSeleted === '80108'){
        FincaLaboro = 'FINCA LA MONICA'
    }else if(sucursalSeleted === '20185'){
        FincaLaboro = 'FINCA LA CEJA'
    }else if(sucursalSeleted === '20194'){
        FincaLaboro = 'FINCA MANAURE'
    }
    setAlert({});                 
    try {
        const fecha = Date.now();            
        const dataRdt = {};       
        dataRdt['IntCodigo_CONC'] = rdt.IntCodigo_CONC;        
        if(Number(horas) > Number(HORA)){
            setAlert({
                msg: 'Horas no pueden superar el max de "9.75 horas" para el trabajador en el día',
                error: true
            })
            //console.log({mensaje: alert.msg}); 
            setTimeout(()=>{
                setAlert({})
            }, 7000)            
            return   
          }else{
            dataRdt['intHora_RDT'] = horas; 
          }      
        dataRdt['intTotal_RDT'] = sumcantidad;       
        dataRdt['dtmFecha_RDT'] = rdt.dtmFecha_RDT;
        dataRdt['IbmLaboro'] = rdt.IbmLaboro;       
        dataRdt['strObservacion_RDT'] = observacion;    
        dataRdt['dtmFechaModifica'] = fecha;
        
        //console.log({datosRDT: dataRdt})
        //console.log({Fecha: fecha})
        
        const response = await requestHttp(
            {
              method: HTTP_VERBS.PUT,
              endpoint: `/rdts/labor/${id}`,
              body: dataRdt,
              token: getToken()
            }
        );            
        /*setAlert({
            msg: response.data,
            error: false
        })*/
        await getRdt();
        showAlert(
            'Registros Actualizados', 
            "Correctamente", 
            SW_ICON.SUCCESS,   
            //() => { navigate('/addrdts')}               
        );
        
        /*setTimeout(()=>{
            setAlert({})                
        }, 7000)*/           
                                   
    } catch (error) {
        //console.log(error)
        setAlert({
            msg: error.response.data,
            error: true
        })
        setTimeout(()=>{
            setAlert({})                
        }, 10000) 
    }     
        
}


const { msg } = alert;

  return (
   <Page>
      <RdtDetailWrapper_Global>
          <RdtDetailWrapper_Global_DOS>
              <LogoImageWrapper>
                <PageTitle>
                  EDITAR RDT
                </PageTitle>
                <img src={"../Logodos.jpg"} alt="imgLogo"/>
              </LogoImageWrapper>
              
            <RdtDetailWrapper>            
              <RdtDetailInfoWrapper>
                <h2>{rdt.strNombreTrabajador}</h2>
                <RdtDetailSubTitle>
                  {rdt.strNombre_SUCU}
                </RdtDetailSubTitle>                
                <h3>Cod Trabajador: {rdt.IntCodigo_EMPL} </h3> 
                {concepto.strUMedida === "Caja"?
                    <FormControlActionTwo>
                        <label>Actualizar Todo</label> 
                        <ButtonIconUpdateAll icon={IoSendSharp} onPress={showAlertYNUpdateAll} />
                    </FormControlActionTwo>  
                :console.log('')}     
                <RdtDetailDescriptionWrapper>
                  <PropertyTypesContainerRow>
                    <PropertyTypesContainerColum>
                        <RdtDetailValueWrapper>                          
                          Laboró en: 
                          <h2>{rdt.strFincaLaboro}</h2>
                          <h3>Ibm: {rdt.IbmLaboro} </h3>
                          <PropertyBusinessType
                            busineesType={`Fecha del RDT: ${rdt.dtmFecha_RDT?rdt.dtmFecha_RDT.split('T')[0]:''} (${nombreDia})`}
                          />
                        </RdtDetailValueWrapper>                        
                      </PropertyTypesContainerColum>
                      <PropertyTypesContainerColum>
                        <RdtDetailValueWrapper>
                          Labor realizada:
                          <h2>{rdt.strConcepto}</h2>
                          <h3>Cod: {rdt.IntCodigo_CONC} </h3>
                          <PropertyBusinessType
                            busineesType={`U.Medida: ${concepto.strUMedida?concepto.strUMedida:''}`}
                          />
                        </RdtDetailValueWrapper>
                      </PropertyTypesContainerColum>
                  </PropertyTypesContainerRow>                  
                </RdtDetailDescriptionWrapper>               
                
                <FormControlAction>
                  <FormControlActionOne>                  
                    <ButtonIcon icon={IoSave} onPress={handleSubmit(onSubmitsave)} />
                    <ButtonIcon icon={IoDuplicate} onPress={() => { navigate(`/RdtDuplicate/${rdt._id}`)}} />
                    <ButtonIcon icon={IoTrashOutline} onPress={showAlertYN} />                   
                  </FormControlActionOne>                  
                  <FormControlActionOne>                   
                    <PropertyTypeLabel 
                        typeId= {`${rdt.employeesId?rdt.employeesId.name:''}`}
                    />&nbsp;&nbsp;               
                    <PropertyTypeLabel 
                            typeId= {`FC: ${rdt.updatedAt?rdt.updatedAt.split('T')[0]:''}`}                                                      
                    />                    
                </FormControlActionOne>
                
              </FormControlAction>                                               
              </RdtDetailInfoWrapper>              
              
            </RdtDetailWrapper>
            { msg && <Alert alert = {alert} />}
            <form>                            
              {rdt.IntCodigo_UDP?
                        <FormControl>
                        <table>
                            <thead>      
                                <tr>
                                    <th>
                                        <FormControlInputSearch>
                                            <label>__UDP__</label>
                                        </FormControlInputSearch>                                        
                                    </th>
                                    <th>
                                        <FormControlInputSearch>
                                            <label>CANTIDAD</label>
                                        </FormControlInputSearch>
                                    </th>
                                    {concepto.IntCodigo == 118?
                                        <FormControlHead>
                                            <th>
                                                <FormControlInputTitel>
                                                    <label>PREM</label>                                                                                                
                                                    <ButtonIconRibbon icon={IoBookmark} cinta={cintaPm} />
                                                </FormControlInputTitel>
                                            </th>
                                            <th>
                                                <FormControlInputTitel>                                                 
                                                    <label>PRES</label>                                                                                                   
                                                    <ButtonIconRibbon icon={IoBookmark} cinta={cintaPs} />
                                                </FormControlInputTitel>
                                            </th>
                                            <th>
                                                <FormControlInputTitel>
                                                    <label>PM+PS</label>
                                                </FormControlInputTitel>
                                            </th>
                                        </FormControlHead>
                                    :''}
                                    {concepto.IntCodigo == 119 || concepto.IntCodigo == '451'?
                                        <FormControlHead>
                                            <th>
                                                <FormControlInputTitel>
                                                    <label>CINTA</label>
                                                </FormControlInputTitel>
                                            </th>
                                 
                                        </FormControlHead>
                                    :''}
                                    <th>
                                        <FormControlInputudp>
                                            <label>A.NETA</label>
                                        </FormControlInputudp>                                        
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <label>DESCRIPCIÓN</label>
                                        </FormControlInputudp>                                        
                                    </th> 
                                    <th>
                                        <FormControlInputudp>
                                            <label>A.BRUTA</label>
                                        </FormControlInputudp>                                        
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <label>LOTE</label>
                                        </FormControlInputudp>                                        
                                    </th>
                                                                                           
                                </tr>          
                            </thead>
                            <tbody>
                                <tr>
                                    <th>
                                        
                                        <FormControlInputSearchudpdel>
                                            <ButtonIconRemove icon={IoRemoveCircleOutline} onPress={cleanStatesudpunoReset} />
                                            &nbsp;
                                            <input  type="number"                                    
                                                    {...register("codigoSearchudpuno")} 
                                                    value={udpunoselected}
                                                    onChange={e => setUdpunoselected(e.target.value)}  
                                                    tabIndex="5"                                                                                                                                                                                                                             
                                            />                                    
                                        </FormControlInputSearchudpdel>                                        
                                    </th>
                                    <th>
                                        <FormControlInputSearchudp>
                                            <input  type="float"                                    
                                                    {...register("cantudpuno")} 
                                                    value={cantudpunoselected}
                                                    onChange={e => setCantudpunoselected(e.target.value)}  
                                                    tabIndex="6"                              
                                            />
                                        </FormControlInputSearchudp>                                        
                                    </th>
                                    {concepto.IntCodigo == 118?
                                        <FormControl>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="number"                                    
                                                            {...register("cantudpunoPrem")} 
                                                            value={cantudpunoPmselected}
                                                            onChange={e => setCantudpunoPmselected(e.target.value)}  
                                                            tabIndex="6"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="number"                                    
                                                            {...register("cantudpunoPres")} 
                                                            value={cantudpunoPsselected}
                                                            onChange={e => setCantudpunoPsselected(e.target.value)}  
                                                            tabIndex="6"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="number"                                    
                                                            {...register("cantudpunoPP")} 
                                                            value={cantudpunoPPselected}
                                                            onChange={e => setCantudpunoPPselected(e.target.value)}  
                                                            tabIndex="6"
                                                            disabled={concepto.IntCodigo == 118?true:false}                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                        </FormControl>
                                    :''}
                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451'?
                                        <FormControlInputSearchcinta>
                                        <td>
                                            <input  type="number"                                    
                                                    {...register("cintaudpuno")} 
                                                    value={cintaudpunoselected}
                                                    onChange={e => setCintaudpunoselected(e.target.value)}  
                                                    tabIndex="6"                                                
                                            />
                                        </td>                                        
                                        <td>
                                            <ButtonIconRibbon icon={IoBookmark} cinta={cinta} />
                                        </td>
                                        <td>
                                            {nameCintaCorto}
                                        </td>
                                    </FormControlInputSearchcinta>                                        
                                        
                                    :''}
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("anetauno")}><span>{udpuno[0]?udpuno[0].intANeta:''}</span></p>
                                        </FormControlInputudp>                                         
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("descriptionuno")}>{udpuno[0]?udpuno[0].strDescripcion:''}</p>
                                        </FormControlInputudp>   
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("abrutauno")}><span>{udpuno[0]?udpuno[0].intABruta:''}</span></p>
                                        </FormControlInputudp> 
                                    </th>
                                    <th>
                                    <FormControlInputudp>
                                        <p {...register("codigoudpnuno")}>{udpuno[0]?udpuno[0].IntCodigo:''}</p>
                                    </FormControlInputudp>
                                    </th>
                                                                        
                                </tr>

                                <tr>
                                    <th>
                                        <FormControlInputSearchudpdel>
                                            <ButtonIconRemove icon={IoRemoveCircleOutline} onPress={cleanStatesudpdosReset} />
                                            &nbsp;
                                            <input  type="number"                                    
                                                    {...register("codigoSearchudpdos")} 
                                                    value={udpdosselected}
                                                    onChange={e => setUdpdosselected(e.target.value)} 
                                                    tabIndex="7"                                                                                                                                                      
                                            />                                    
                                        </FormControlInputSearchudpdel>                                        
                                    </th>
                                    <th>
                                        <FormControlInputSearchudp>
                                            <input  type="float"                                    
                                                    {...register("cantudpdos")} 
                                                    value={cantudpdosselected}
                                                    onChange={e => setCantudpdosselected(e.target.value)}    
                                                    tabIndex="8"                                                                                            
                                                                                                                    
                                            />
                                        </FormControlInputSearchudp>                                        
                                    </th>
                                    {concepto.IntCodigo == 118?
                                        <FormControl>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpdosPrem")} 
                                                            value={cantudpdosPmselected}
                                                            onChange={e => setCantudpdosPmselected(e.target.value)}  
                                                            tabIndex="8"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpdosPres")} 
                                                            value={cantudpdosPsselected}
                                                            onChange={e => setCantudpdosPsselected(e.target.value)}  
                                                            tabIndex="8"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpdosPP")} 
                                                            value={cantudpdosPPselected}
                                                            onChange={e => setCantudpdosPPselected(e.target.value)}    
                                                            tabIndex="8"                                                                                            
                                                            disabled={concepto.IntCodigo == 118?true:false}                                                                                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                        </FormControl>
                                    :''}
                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451'?
                                        <FormControlInputSearchcinta>
                                        <td>
                                            <input  type="number"                                    
                                                    {...register("cintaudpdos")} 
                                                    value={cintaudpdosselected}
                                                    onChange={e => setCintaudpdosselected(e.target.value)}  
                                                    tabIndex="8"                                                
                                            />
                                        </td>                                        
                                        <td>
                                            <ButtonIconRibbon icon={IoBookmark} cinta={cintados} />
                                        </td>
                                        <td>
                                            {nameCintadosCorto}
                                        </td>
                                    </FormControlInputSearchcinta>                                        
                                        
                                    :''}
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("anetados")}><span>{udpdos[0]?udpdos[0].intANeta:''}</span></p>
                                        </FormControlInputudp>                                         
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("descriptiondos")}>{udpdos[0]?udpdos[0].strDescripcion:''}</p>
                                        </FormControlInputudp>   
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p {...register("abrutados")}><span>{udpdos[0]?udpdos[0].intABruta:''}</span></p>
                                        </FormControlInputudp> 
                                    </th>
                                    <th>
                                    <FormControlInputudp>
                                        <p {...register("codigoudpndos")}>{udpdos[0]?udpdos[0].IntCodigo:''}</p>
                                    </FormControlInputudp>
                                    </th>
                                                                        
                                </tr>

                                <tr>
                                    <th>
                                        <FormControlInputSearchudpdel>
                                            <ButtonIconRemove icon={IoRemoveCircleOutline} onPress={cleanStatesudptresReset} />
                                            &nbsp;
                                            <input  type="number"                                    
                                                    {...register("codigoSearchudptres")} 
                                                    value={udptresselected}
                                                    onChange={e => setUdptresselected(e.target.value)}  
                                                    tabIndex="9"                                                                                                                                                    
                                            />                                    
                                        </FormControlInputSearchudpdel>                                        
                                    </th>
                                    <th>
                                        <FormControlInputSearchudp>
                                            <input  type="float"                                    
                                                    {...register("cantudptres")} 
                                                    value={cantudptresselected}
                                                    onChange={e => setCantudptresselected(e.target.value)} 
                                                    tabIndex="10"                                                                                                                                                             
                                            />
                                        </FormControlInputSearchudp>                                        
                                    </th>
                                    {concepto.IntCodigo == 118?
                                        <FormControl>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudptresPrem")} 
                                                            value={cantudptresPmselected}
                                                            onChange={e => setCantudptresPmselected(e.target.value)}  
                                                            tabIndex="10"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudptresPres")} 
                                                            value={cantudptresPsselected}
                                                            onChange={e => setCantudptresPsselected(e.target.value)}  
                                                            tabIndex="10"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudptresPP")} 
                                                            value={cantudptresPPselected}
                                                            onChange={e => setCantudptresPPselected(e.target.value)} 
                                                            tabIndex="10" 
                                                            disabled={concepto.IntCodigo == 118?true:false}                                                                                                                                                                                          
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                        </FormControl>
                                    :''}
                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451'?
                                        <FormControlInputSearchcinta>
                                        <td>
                                            <input  type="number"                                    
                                                    {...register("cintaudptres")} 
                                                    value={cintaudptresselected}
                                                    onChange={e => setCintaudptresselected(e.target.value)}  
                                                    tabIndex="10"                                                
                                            />
                                        </td>                                        
                                        <td>
                                            <ButtonIconRibbon icon={IoBookmark} cinta={cintatres} />
                                        </td>
                                        <td>
                                            {nameCintatresCorto}
                                        </td>
                                    </FormControlInputSearchcinta>                                        
                                        
                                    :''}
                                    <th>
                                        <FormControlInputudp>
                                            <p {...register("anetatres")}><span>{udptres[0]?udptres[0].intANeta:''}</span></p>
                                        </FormControlInputudp>                                         
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p  {...register("descriptiontres")}>{udptres[0]?udptres[0].strDescripcion:''}</p>
                                        </FormControlInputudp>   
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p {...register("abrutatres")}><span>{udptres[0]?udptres[0].intABruta:''}</span></p>
                                        </FormControlInputudp> 
                                    </th>
                                    <th>
                                    <FormControlInputudp>
                                        <p {...register("codigoudpntres")}>{udptres[0]?udptres[0].IntCodigo:''}</p>
                                    </FormControlInputudp>
                                    </th>
                                                                        
                                </tr>

                                <tr>
                                    <th>
                                        <FormControlInputSearchudpdel>                                         
                                            <ButtonIconRemove icon={IoRemoveCircleOutline} onPress={cleanStatesudpcuatroReset} />
                                            &nbsp;
                                            <input  type="number"                                    
                                                    {...register("codigoSearchudpcuatro")} 
                                                    value={udpcuatroselected}
                                                    onChange={e => setUdpcuatroselected(e.target.value)}
                                                    tabIndex="11"                                                                                                                                                      
                                            />                                                                               
                                        </FormControlInputSearchudpdel>                                        
                                    </th>
                                    <th>
                                        <FormControlInputSearchudp>
                                            <input  type="float"                                    
                                                    {...register("cantudpcuatro")}  
                                                    value={cantudpcuatroselected}
                                                    onChange={e => setCantudpcuatroselected(e.target.value)} 
                                                    tabIndex="12"                                                                                                                                                              
                                            />
                                        </FormControlInputSearchudp>                                        
                                    </th>
                                    {concepto.IntCodigo == 118 ?
                                        <FormControl>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpcuatroPrem")} 
                                                            value={cantudpcuatroPmselected}
                                                            onChange={e => setCantudpcuatroPmselected(e.target.value)}  
                                                            tabIndex="12"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpcuatroPres")} 
                                                            value={cantudpcuatroPsselected}
                                                            onChange={e => setCantudpcuatroPsselected(e.target.value)}  
                                                            tabIndex="12"                              
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>
                                            <th>
                                                <FormControlInputSearchudp>
                                                    <input  type="float"                                    
                                                            {...register("cantudpcuatroPP")}  
                                                            value={cantudpcuatroPPselected}
                                                            onChange={e => setCantudpcuatroPPselected(e.target.value)} 
                                                            tabIndex="12" 
                                                            disabled={concepto.IntCodigo == 118?true:false}                                                                                                                                                                                           
                                                    />
                                                </FormControlInputSearchudp>                                        
                                            </th>                                            
                                        </FormControl>
                                    :''}
                                    {concepto.IntCodigo == '119' || concepto.IntCodigo == '451'?
                                        <FormControlInputSearchcinta>
                                        <td>
                                            <input  type="number"                                    
                                                    {...register("cintaudpcuatro")} 
                                                    value={cintaudpcuatroselected}
                                                    onChange={e => setCintaudpcuatroselected(e.target.value)}  
                                                    tabIndex="12"                                                
                                            />
                                        </td>                                        
                                        <td>
                                            <ButtonIconRibbon icon={IoBookmark} cinta={cintacuatro} />
                                        </td>
                                        <td>
                                            {nameCintacuatroCorto}
                                        </td>
                                    </FormControlInputSearchcinta>                                        
                                        
                                    :''}
                                    <th>
                                        <FormControlInputudp>                                            
                                            <p {...register("anetacuatro")}><span>{udpcuatro[0]?udpcuatro[0].intANeta:''}</span></p>
                                        </FormControlInputudp>                                         
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p {...register("descriptioncuatro")}>{udpcuatro[0]?udpcuatro[0].strDescripcion:''}</p>
                                        </FormControlInputudp>   
                                    </th>
                                    <th>
                                        <FormControlInputudp>
                                            <p {...register("abrutacuatro")}><span>{udpcuatro[0]?udpcuatro[0].intABruta:''}</span></p>
                                        </FormControlInputudp> 
                                    </th>
                                    <th>
                                    <FormControlInputudp>
                                        <p {...register("codigoudpncuatro")}>{udpcuatro[0]?udpcuatro[0].IntCodigo:''}</p>
                                    </FormControlInputudp>
                                    </th>                                                                        
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Total:</th>
                                    <th>{sumcantidad.toFixed(2)}</th>                                    
                                    {concepto.IntCodigo == 118?
                                        <FormControlHead>
                                            <th>{sumcantPm.toFixed(0)}</th>
                                            <th>{sumcantPs.toFixed(0)}</th>
                                            <th>{sumcantPP.toFixed(0)}</th>
                                        </FormControlHead>                                                                                                                               
                                    :''}                                                                  
                                    
                                </tr>
                            </tfoot>
                        </table>
                        </FormControl>:''}
              <FormControl>
                <FormControlSeccionOne>
                            <FormControlSeccionTwo>
                              <FormControlInputSearch>
                                      <label>HORAS_LAB</label>
                                      <input  type="number"                                    
                                              {...register("horas")} 
                                              value={horas}
                                              onChange={e => setHoras(e.target.value)}  
                                              tabIndex="13"  
                                              id="input3"                                                                                                                                                              
                                      />                                    
                                  </FormControlInputSearch>
                                  <FormControlInputSearch>
                                      <label>CANT._TOTAL</label>
                                      <input  type="number"                                    
                                              {...register("total")}
                                              value={(sumcantidad)}
                                              onChange={e => setSumcantidad(e.target.value)} 
                                              disabled={concepto.intEXUDP === 1?true:false}                                             
                                              tabIndex="14"                                                                                                           
                                              id="input4"          
                                      />                                    
                                  </FormControlInputSearch>
                            </FormControlSeccionTwo>                                                                                                   
                          
                          <FormControl>
                              <FormControlInputSearch>
                                      <label>OBSERVACIÓN</label>
                                      <input  type="text"                                    
                                              {...register("observacion")}
                                              value={observacion}
                                              onChange={e => setObservacion(e.target.value)}   
                                              tabIndex="15"                                             
                                              id="input5"
                                                                                                                                                                                          
                                      />                                    
                              </FormControlInputSearch>
                          </FormControl>                                                                                                                                                                                         
                </FormControlSeccionOne>      
              </FormControl>
              <FormControlActionTwo>
                    <ButtonIcon icon={IoArrowBack} onPress={() => { navigate('/addrdts')}} />
              </FormControlActionTwo>
            </form>
          </RdtDetailWrapper_Global_DOS>
      </RdtDetailWrapper_Global>
   </Page>
  )
}
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { useRdt } from "../../hooks/useRdt";
import { getToken, removeToken } from "../../utils/TokenLS";
import { showAlert, SW_ICON } from "../../utils/swAlert";
import { CONTENT_TYPES, HTTP_VERBS, requestHttp } from "../../utils/HttpRequest";
import { PropertyCardRdts } from "./PropertyCardRdts";
import { PropertyButtonWrapper, PropertyCardWrapper, 
         PropertyCardWrapperTr, 
         PropertyDescriptionWrapper, 
         PropertyImageWrapper, 
         PropertyInfoWrapper, 
         PropertyValueWrapper } from "./PropertyCardRdts/styles";
import { FormControl, FormControlInput, FormControlInputDuplicate, SubTitle } from "../../globalStyles";
import { Page } from "../../components/Page";
import { PropertyTypesContainerColum, 
         PropertyTypesContainerHead, 
         PropertyTypesContainerRow, 
         RdtsDetailTitle, 
         RdtsWrapper, 
         RdtExportButton,
        RdtsDetailInfo } from "./styles";
import { PropertyProfile } from "../Home/components/PropertyProfile";
import { LogoImageWrapper } from "../../components/Logo/styles";
import { ButtonIconRemove } from "../../components/ButtonIconRemove";
import { IoTrashOutline, 
  IoRemoveCircleOutline,
  IoRadioButtonOffOutline,
  IoArrowBackCircleOutline,
  IoArrowBack,
  IoDuplicate,
  IoAddCircleOutline,
  IoSave, 
  IoSend} from "react-icons/io5";
import swal from 'sweetalert';
import Alert from "../../components/Alert/Alert"
import ExportExcel from "react-export-excel";
import { Button } from "../../components/Button";

const ALL_SUCURSALES = 0;
const fecha = Date.now();
const suma = 0; 

export const Rdtsnews = () => {

  
  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelColumn;

  const { auth, closeSeccionAuth } = useAuth();
  const { rdtprov, setRdtprov } = useRdt();
  const [ rdts, setRdts ] = useState([]);
  const [ sucursal, setSucursal ] = useState([]);
  const [ sucursalSeleted, setSucursalSelected] = useState(ALL_SUCURSALES);
  
  const [ codtrabajador, setCodtrabajador ] = useState('');
  const [ codlabor, setCodlabor ] = useState('');
  const [ codudp, setCodudp ] = useState('');
  const [ fechaini, setFechaIni ] = useState('');
  const [ fechafin, setFechafin ] = useState('');
  const [ date, setDate ] = useState('');

  const [ sumtotal, setSumtotal ] = useState(suma);
  const [ sumcant, setSumcant ] = useState(suma);
  const [ sumcantdos, setSumcantdos ] = useState(suma);
  const [ sumcanttres, setSumcanttres ] = useState(suma);
  const [ sumcantcuatro, setSumcantcuatro ] = useState(suma);
  const [ sumhoras, setSumhoras ] = useState(suma);

  const [duplicar, setDuplicar] = useState('B')
  const [check, setCheck] = useState(false)
  const [checkSelected, setCheckSelected] = useState([])
  
  const [alert, setAlert] = useState({}) 
  
  const navigate = useNavigate();

  const { 
    register,
    handleSubmit,     
    formState:{
        errors,
        isValid
    }  
 } = useForm({ 
      mode: 'onChange',
      defaultValues: {
        checkbox: []
      }      
  }); 

 //Validar dia de la semana
 const fechaComoCadena = fechaini.split('T')[0] +  " 00:01:59"; // día semana
 const fechaComoCadenaf = fechafin.split('T')[0] +  " 00:01:59"; // día semana
 const dias = [
     'DOMINGO',
     'LUNES',
     'MARTES',
     'MIERCOLES',
     'JUEVES',
     'VIERNES',
     'SABADO',
 ];
 const numeroDia = new Date(fechaComoCadena).getDay();
 const nombreDia = dias[numeroDia];

 //hoy
 const tiempoTranscurrido = Date.now(); 
 const fechai = new Date(fechaComoCadena);
 const fechaf = new Date(fechaComoCadenaf);
 const hoy = new Date(tiempoTranscurrido);
 const rango = new Date(hoy - fechai);
 const tiempo = new Date(189999999);

    //console.log({rango: rango})
    //console.log({cadena: fechaComoCadena})
    //console.log({tiempo: tiempo})
    //console.log({tiempoViernes: tiempoViernes})
    //console.log({tiempoSabado: tiempoSabado})
    //console.log({numeroDiaRDT: numeroDia})
    //console.log({fecha: fecha})
    //console.log({tiempoTranscurrido: tiempoTranscurrido})   
    //console.log("Fecha actual: ", hoy); 
    //console.log("Calendario: ", fecha);   
    //console.log("nombre dia: ", nombreDia); 

  
 useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return   
            await getSucursalesAll();                                          
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();                 
  },[auth])

  //Si estoy editando
  useEffect(() => {  
    const getData = async ()=>{            
        try {
            const token = getToken();
            if(!token) return            
            await rdtprov.ibm?setSucursalSelected(rdtprov.ibm):null(); 
            await rdtprov.dateRdt?setFechaIni((rdtprov.dateRdt).split('T')[0]):null();                                
        } catch (error) {
            console.log(error)
        }             
    }    
    getData();               
  },[rdtprov])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                                         
            await getRdts(); 
            setCheck(false);           
           setDuplicar('B');
           setCheckSelected([]);                
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[sucursalSeleted])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
          const token = getToken();
            if(!token) return                               
           await getRdts();  
           setCheck(false);           
           setDuplicar('B');
           setCheckSelected([]);            
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[fechaini])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await getRdts();                     
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[codtrabajador])

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await getRdts();                                                                 
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[codlabor])  

  useEffect(() => {  
    const getDataRdts = async ()=>{            
        try {
            const token = getToken();
            if(!token) return                               
           await onSubmitCantidad();                                                                  
        } catch (error) {
            console.log(error)
        }             
    }    
    getDataRdts();                 
  },[rdts])

  //Sucursales
  const handleChange = event => {
    setSucursalSelected(event.target.value)             
  }
  const handleChangeDate = event => {
    setFechaIni(event.target.value)             
  }
  const handleChangeDateduplicate = event => {
    setFechafin(event.target.value)             
  }
  const handleChangeCodtrabajador = event => {
    setCodtrabajador(event.target.value)             
  }
  const handleChangeCodlabor = event => {
    setCodlabor(event.target.value)             
  }
  //habilitar duplicado
  const handleChangeCheckduplicate = event => {
    
    if(event.target.value === 'A'){
      setDuplicar('B')
      setCheck(false)      
    }else{
      setDuplicar('A')  
      setCheck(true)
    }       
  }
  //Seleccionar novedades a duplicar
  const handleChangeCheckSelected = event => {   
   /* var listado;   
    if(checkSelected.includes(event.target.value)){
      listado = checkSelected.filter(elemento=>elemento!==event.target.value)
    }else{
      listado = checkSelected.concat(event.target.value)
    }
    setCheckSelected(listado)      */
  }
  //Sucursals
  const getSucursalesAll = async ()=>{
    try {            
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/sucursal',                         
                params: makeSucursalesFilters()                                     
            }
        );             
        const {data} = response;                
        setSucursal(data);                                   
    } catch (error) {
        console.log(error)
    }
  }
  const makeSucursalesFilters = ()=>{
    const filters = {};           
    return filters;       
  }  
  //getRdts
  const getRdts = async ()=>{
      //console.log({sucursal: sucursalSeleted})
      if(!fechaini){
        setRdts([])
        return
        //console.log('Fecha vacia')
      }
      
        try {                      
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.GET,
                  endpoint: '/rdtsnews',                         
                  params: makePropertiesFilters()                                     
              }
          );             
          const {data} = response;                
          data?setRdts(data):console.log('');        
          } catch (error) {
              if(error.response.status === 401){
                  hendleCloseSeccion();
              }else{
                  console.log(error)
              }
          }
          
            //console.log({hacerConsulta: sucursalSeleted})        
                 
  }
  //getRdts
  const getRdtsUdp = async ()=>{
    //console.log('consulta desde UDP')
    if(!fechaini){
      setRdts([])
      return
      //console.log('Fecha vacia')
    }
    if(sucursalSeleted === '' || sucursalSeleted === 0 || sucursalSeleted === '90021' || sucursalSeleted === '90020'){
      setRdts([])
      return
      //console.log({nohacerConsulta: sucursalSeleted})        
    }else{
      //console.log({sucursal: sucursalSeleted}) 
      try {                      
        const response = await requestHttp(
            {
                method: HTTP_VERBS.GET,
                endpoint: '/Rdtsnews/analyses',                         
                params: makePropertiesFilters()                                     
            }
        );             
        const {data} = response;                
        data?setRdts(data):console.log('');        
        } catch (error) {
            if(error.response.status === 401){
                hendleCloseSeccion();
            }else{
                console.log(error)
            }
        }
          //console.log({hacerConsulta: sucursalSeleted})        
    }                   
  }
  //Sumar cantidad
  const onSubmitCantidad = async (data) =>{    
    if(Object.keys(rdts).length > 0 && Object.keys(codlabor).length > 0 || Object.keys(codudp).length > 0){
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;        
      for (let i = 0; i < rdts.length ; i++){          
          a = a + rdts[i].intTotal_RDT;
          b = b + rdts[i].intCantidad_UDP;
          c = c + rdts[i].intCantidad_UDPdos;
          d = d + rdts[i].intCantidad_UDPtres;
          e = e + rdts[i].intCantidad_UDPcuatro;          
      }
      setSumtotal(a)
      setSumcant(b)
      setSumcantdos(c)
      setSumcanttres(d)
      setSumcantcuatro(e)
      setSumhoras(0)        
    }else if(Object.keys(rdts).length > 0 && Object.keys(codtrabajador).length > 0){
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;
        let e = 0;        
        for (let i = 0; i < rdts.length ; i++){          
            a = a + rdts[i].intTotal_RDT;
            b = b + rdts[i].intCantidad_UDP;
            c = c + rdts[i].intTotal_RDT;
            d = d + rdts[i].intTotal_RDT;
            e = e + rdts[i].intHora_RDT;          
        }
        setSumtotal(0)
        setSumcant(0);
        setSumcantdos(0)
        setSumcanttres(0)
        setSumcantcuatro(0)
        setSumhoras(e)  
    }else{
      setSumtotal(0);
      setSumcant(0);
      setSumcantdos(0)
      setSumcanttres(0)
      setSumcantcuatro(0)
      setSumhoras(0);
    }        
                            
  }
  const makePropertiesFilters = ()=>{
      const filters = {};                
          filters['Fecha_INI'] = fechaini; 
          filters['Fecha_FIN'] = fechaini; 
          filters['IbmLaboro'] = sucursalSeleted;   
          filters['IntCodigo_EMPL'] = codtrabajador;    
          filters['IntCodigo_CONC'] = codlabor;
          filters['IntCodigo_UDP'] = codudp;
        //filters['employeesId'] = auth.data._id;     
        //console.log(fechaini);
      return filters;       
  }
  //Delete Novedad RDT
  const showAlertYN = (id) =>{
      //Validar dia de la semana
      const fechaComoCadena = fechaini?fechaini.split('T')[0] +  " 00:01:59":console.log(''); // día semana  
      const fecha = new Date(fechaComoCadena);
      const numeroDia = new Date(fechaComoCadena).getDay();
      //hoy
      const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido);
      const rango = new Date(hoy - fecha);
      const tiempo = new Date(189999999);

      //console.log({rango: rango})
      //console.log({tiempo: tiempo})

      //Vallido que si este dentro de un rango permitido para duplicar
      //valido si dia es viernes o sabado                          
      if(numeroDia >= 5){
        //rango para el viernes de 4 dias.
        if(numeroDia == 5){                
            if(rango > 363599999){
                setAlert({
                    msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                    error: true
                })
                //console.log({mensaje: alert.msg}); 
                setTimeout(()=>{
                    setAlert({})
                }, 7000)            
                return
            }
        }else{
            //rango para el sabado de 3 dias.                     
                if(rango > 276999999){
                //if(rango > 363599999){ //Festivo dia lunes
                    setAlert({
                        msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
                        error: true
                    })
                    //console.log({mensaje: alert.msg}); 
                    setTimeout(()=>{
                        setAlert({})
                    }, 7000)            
                    return
                }
        }
      }else{
        if(rango > 189999999){
          setAlert({
              msg: 'Usted ya no puede eliminar este registro, porque esta fuera del tiempo permitido',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return
        }
      }
      //console.log({id: id})
      swal({
          title: "Esta usted seguro?",
          text: "Una vez eliminado, no podras recuperar este registro!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
          if (willDelete) {   
              rdtDelete(id)                
          } else {
          //swal("Su registro esta a salvo!");
          return
          }
          //console.log(willDelete)
      });
  }
  const rdtDelete = async (id) =>{             
      try {           
          const response = await requestHttp(
              {
                  method: HTTP_VERBS.DELETE,
                  endpoint: `/rdtsnews/${id}`,               
                  token: getToken()               
              }
          );
          getRdts()
          showAlert(
              'RDT', 
              "Eliminado Correctamente", 
              SW_ICON.SUCCESS              
          );                              
              
          } catch (error) { 
              if(error.response.status === 401){
                  //hendleCloseSeccion();
              }else{
                  showAlert('Error', "Eliminación Incorrecta", SW_ICON.ERROR);
              }            
          }        
  }
  //Duplicate
  const handleAddDuplicate = async (data) => {
    //valido si dia es viernes o sabado                          
    if(numeroDia >= 5){
      //rango para el viernes de 4 dias.
      if(numeroDia == 5){                
          if(rango > 363599999){
              setAlert({
                  msg: 'Usted ya no puede realizar registros de Novedades RDT para este dia',
                  error: true
              })
              //console.log({mensaje: alert.msg}); 
              setTimeout(()=>{
                  setAlert({})
              }, 7000)            
              return
          }
      }else{
          //rango para el sabado de 3 dias.                     
              if(rango > 276999999){
              //if(rango > 363599999){ //Festivo dia lunes
                  setAlert({
                      msg: 'Usted ya no puede realizar registros de Novedades RDT para este dia',
                      error: true
                  })
                  //console.log({mensaje: alert.msg}); 
                  setTimeout(()=>{
                      setAlert({})
                  }, 7000)            
                  return
              }
      }
    }else{
      //Rango de 2 dias para registrar
      if(rango > 189999999){      
          setAlert({
              msg: 'Usted ya no puede realizar registros de Novedades RDT para este dia',
              error: true
          })
          //console.log({mensaje: alert.msg}); 
          setTimeout(()=>{
              setAlert({})
          }, 7000)            
          return
      }
    } 

    //Valido si Fecha de novedad es mayor a la actual
    if(fechai > fechaf){
      setAlert({
          msg: 'Fecha de novedad del RDT no puede ser "mayor" a la fecha a duplicar',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
    }
    //Valido si Fecha de novedad es igual a la duplicada
      if(fechaini === fechafin){
      setAlert({
          msg: 'Fecha de novedad del RDT no puede ser "igual" a la fecha a duplicar',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
    }
    //Valido si se selecciono Fecha de novedad a duplicar
    if(!fechafin){
      setAlert({
          msg: 'Seleccione Fecha Actual de novedad a duplicar registros',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
    }
    //Valido si Fecha es mayor a la actual
    if(fechaf > hoy){
      setAlert({
          msg: 'Fecha no puede ser "mayor" a la fecha actual',
          error: true
      })
      //console.log({mensaje: alert.msg}); 
      setTimeout(()=>{
          setAlert({})
      }, 7000)            
      return     
    }
    //console.log(data.checkbox.length)
    //console.log(data.checkbox)
    //Valido que este seleccionado una labor
    //console.log({size: checkSelected.length})
    if(!data.checkbox.length){
        setAlert({
            msg: 'Seleccione registros de los trabajadores a duplicar con novedad',
            error: true
        })       
        setTimeout(()=>{            
            setAlert({})
        }, 7000)
        return           
    }           
        for (let i = 0; i < data.checkbox.length ; i++){                                       
            //console.log(rdtstrabajador[data.checkbox[i]])
                                
                const fechar = Date.now();    
                const fechaRDTactual = new Date(fechar);
                //console.log(fechaini)
                //console.log({getDate: fechaRDTactual.getDate()})
                //console.log({getDay: fechaRDTactual.getDay()})
                //console.log({getFullYear: fechaRDTactual.getFullYear()})
                //console.log({getMonth: fechaRDTactual.getMonth()})
                //console.log({getTime: fechaRDTactual.getTime()})
                //console.log({getUTCDate: fechaRDTactual.getUTCDate()}) 
                //console.log({toDateString:fechaRDTactual.toDateString()}) 
                //console.log({toLocaleDateString:fechaRDTactual.toLocaleDateString()}) 
                //console.log({toLocaleString:fechaRDTactual.toLocaleString()}) 
                
                const dataRdt = {};
                dataRdt['IntCodigo_EMPL'] = rdts[data.checkbox[i]].IntCodigo_EMPL;
                dataRdt['strDocumento'] = rdts[data.checkbox[i]].strDocumento;
                dataRdt['strNombreTrabajador'] = rdts[data.checkbox[i]].strNombreTrabajador;
                dataRdt['strCodigo_SUCU'] = rdts[data.checkbox[i]].strCodigo_SUCU;
                dataRdt['strNombre_SUCU'] = rdts[data.checkbox[i]].strNombre_SUCU;
                dataRdt['strNombre_EMPR'] = rdts[data.checkbox[i]].strNombre_EMPR;
                dataRdt['IntCodigo_CONC'] = rdts[data.checkbox[i]].IntCodigo_CONC;
                dataRdt['strConcepto'] = rdts[data.checkbox[i]].strConcepto;
                dataRdt['intHora_RDT'] = rdts[data.checkbox[i]].intHora_RDT;
                dataRdt['intTotal_RDT'] = rdts[data.checkbox[i]].intTotal_RDT;
                dataRdt['dtmFecha_RDT'] = fechafin;
                dataRdt['IbmLaboro'] = rdts[data.checkbox[i]].IbmLaboro;
                dataRdt['strFincaLaboro'] = rdts[data.checkbox[i]].strFincaLaboro;
                dataRdt['strObservacion_RDT'] = rdts[data.checkbox[i]].strObservacion_RDT;
                dataRdt['email_USUA'] = auth.data.email;
                dataRdt['dtmFechaCrea'] = fechaRDTactual;
                dataRdt['dtmFechaModifica'] = fechaRDTactual;
                dataRdt['employeesId'] = auth.data._id;
                
                //console.log({dataRdt: dataRdt})
                sendDataRdt(dataRdt)                                                          
        }    
       
    
    showAlert(
        'Duplicado', 
        "Correctamente", 
        SW_ICON.SUCCESS,
        () => {navigate('/addrdtsnews')}                       
    );
  }
  const sendDataRdt = async (dataRdt) =>{             

    try {           
        const response = await requestHttp(
            {
                endpoint: '/rdtsnews',
                body: dataRdt
            }
        );            
    } catch (error) {    
        //setIterations(false)            
        /*console.log(dataRdt.IntCodigo_EMPL,dataRdt.IntCodigo_CONC) 
        await setEmplInconsistencia({Empl: dataRdt.IntCodigo_EMPL, CONC: dataRdt.IntCodigo_CONC})       */
        showAlert('Error', `"Duplicado Incorrecto"`, SW_ICON.ERROR);
    }       
   
  }
  //Close session if token expired
  const hendleCloseSeccion = async ()=>{
    closeSeccionAuth()
    removeToken()
    showAlert(
        'Inicie Seccion Nuevamente', 
        "Su token puede estar vencido", 
        SW_ICON.INFO,
        () => { navigate('/Login')}    
    );
  }
  //Mensajes 
  const { msg } = alert;
  
  return (
    <Page>       
        <RdtsDetailTitle>
          CONSULTA DE NOVEDADES RDTS
        </RdtsDetailTitle> 
        { msg && <Alert alert = {alert} />}
      <form>
          <PropertyTypesContainerRow>            
            <PropertyTypesContainerColum>
                  <LogoImageWrapper>                                
                    <img src={"Logodos.jpg"} alt="imgLogo"/>                               
                  </LogoImageWrapper>                   
              </PropertyTypesContainerColum>
              <PropertyTypesContainerColum>
              <FormControl>
                <FormControlInput>
                        <label>FINCA REPORTADA</label>                    
                        <select id="sucursal"
                                    {...register("sucursal")}
                                    onChange={handleChange}
                                    value={sucursalSeleted}
                                    disabled={ auth.data.role != 1 && rdtprov.ibm != 90021?true:false}>
                                <option value={""}>--Seleccione--</option>
                                {sucursal.map(item=>(                                    
                                        <option key={item.strCodigo} 
                                                value={item.strCodigo}                                                                                               
                                                id={item.strCodigo}
                                    >{item.strNombre}</option>
                                    )
                                    )
                                }  
                                                                                                                                                                            
                        </select>
                </FormControlInput>
              </FormControl>  
              <FormControl>
                <FormControlInput>
                            <label>FECHA DEL RDT</label>
                            <input  type="date"
                                    {...register("fechaini")} 
                                    onChange={handleChangeDate}
                                    value={fechaini}
                            />                            
                            {errors.fechaini?.type === 'required' && <span>Este Campo es requerido</span>}
                </FormControlInput>
              </FormControl> 
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
              <FormControl>
                <FormControlInput>
                            <label>COD.TRABAJDOR:</label>
                            <input  type='text'                                    
                                     {...register("codtrabajador")}                                                                        
                                     onChange={handleChangeCodtrabajador}
                            />
                  </FormControlInput>
              </FormControl>
              <FormControl>
                <FormControlInput>
                            <label>COD.AUSENCIA:</label>                            
                            <input  type='text'                                    
                                     {...register("codlabor")}                                                                        
                                     onChange={handleChangeCodlabor}
                            />
                  </FormControlInput>
                </FormControl>                 
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
              <RdtsDetailInfo>
                <p> 
                      USU:<span>{auth.data ? auth.data.email : 'Usuario no registrado'}</span><br/>
                      IBM:<span>{sucursalSeleted}</span><br/>
                      FEC:<span>{fechaini}</span><br/><br/>
                      DIA:<span>"{nombreDia}"</span>                                                                                                                             
                </p> 
                </RdtsDetailInfo>
            </PropertyTypesContainerColum>
            <PropertyTypesContainerColum>
                <LogoImageWrapper>                                
                                                
                </LogoImageWrapper> 
            </PropertyTypesContainerColum>
            
        </PropertyTypesContainerRow>  
        
      </form>
      {auth.data.role === 1?
        <RdtExportButton>
          <ExcelFile
                      element={<button>Exportar Novedades</button>}
                      filename="Rdtnovedadesexportacion" >
                      <ExcelSheet data={rdts} name={fechaini}>
                        <ExcelColumn label="SEM" value="" />
                        <ExcelColumn label="FECHA_NOVEDAD" value="dtmFecha_RDT" />
                        <ExcelColumn label="BORRAR" value="" />
                        <ExcelColumn label="FINCA_REPORTO" value="strFincaLaboro" />
                        <ExcelColumn label="CODEMPL" value="IntCodigo_EMPL" />
                        <ExcelColumn label="NAMEEMPL" value="strNombreTrabajador" />
                        <ExcelColumn label="NOVEDAD" value="strConcepto" />
                        <ExcelColumn label="OBSERVACIONES" value="strObservacion_RDT" /> 
                        <ExcelColumn label="USUARIO_REPORTO" value="email_USUA" />                    
                      </ExcelSheet>
                    </ExcelFile>
      </RdtExportButton>:''}
      {auth.data.role === 3?
        <FormControl>               
          <FormControlInputDuplicate>           
              <input { ...register("checkboxDuplicate")} 
                          type="checkbox" 
                          onChange={handleChangeCheckduplicate}
                          checked={check}
                          value={duplicar}/>         
          </FormControlInputDuplicate>
          <FormControlInputDuplicate>
              <label>Duplicar Novedades</label>                                                                                              
          </FormControlInputDuplicate>
          {duplicar === 'A'? 
            <FormControlInputDuplicate>
                <label>Selecione Fecha Actual</label> 
                <input  type="date"
                            {...register("fechafin")} 
                            onChange={handleChangeDateduplicate}
                            value={fechafin}/>                              
            </FormControlInputDuplicate>:''}
        </FormControl>:''}       
      <br/> 
      <table>
        <thead>      
          <tr>
            <th>Datos Trabajador</th>            
            <th>Cod</th>
            <th>Ausencia</th>
            <th>Total</th>                       
            <th>Observación</th>           
            <th>Ibm</th>   
            <th>Reporto</th>
            {duplicar === 'A'? 
            <th>Seleccione Novedades</th>:''}
          </tr>          
        </thead>
        <tbody>
          {rdts.map((item, key) => (                     
            <tr>              
              <th>
                
                <PropertyCardWrapperTr>
                    <PropertyButtonWrapper>
                    {key}&nbsp;
                    <ButtonIconRemove icon={IoTrashOutline} onPress={()=>showAlertYN(item._id)} />
                    </PropertyButtonWrapper>
                    <PropertyCardWrapper to={`/RdtnewsDetail/${item._id}`}>                  
                      <PropertyInfoWrapper>
                        {item.strNombreTrabajador} 
                        <SubTitle>
                            <span>{item.IntCodigo_EMPL} </span> - {item.strNombre_SUCU}
                        </SubTitle>
                      </PropertyInfoWrapper>               
                    </PropertyCardWrapper>
                </PropertyCardWrapperTr>
              </th>                         
              <th>
                <PropertyDescriptionWrapper>
                 <p>{item.IntCodigo_CONC}</p>
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.strConcepto}
                </PropertyDescriptionWrapper>
              </th>
              <th>
              <PropertyDescriptionWrapper>
                  <p>{(item.intTotal_RDT).toFixed(2)}</p>
                </PropertyDescriptionWrapper>
              </th>              
              <th>
                <PropertyDescriptionWrapper>
                  {item.strObservacion_RDT}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.IbmLaboro === item.strCodigo_SUCU ?"":item.IbmLaboro}
                </PropertyDescriptionWrapper>
              </th>
              <th>
                <PropertyDescriptionWrapper>
                  {item.email_USUA}
                </PropertyDescriptionWrapper>
                
              </th>
              {duplicar === 'A'? 
                <th>
                  <input {...register("checkbox")} 
                          type="checkbox"                          
                          onChange={handleChangeCheckSelected}                          
                          value={key} /> 
                </th>:''}                                                                                            
            </tr>                    
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>T.REG: {rdts.length}</th>            
            <th></th>
            <th></th>
            <th>{(sumtotal).toFixed(2)}</th>
            <th></th>
            <th></th>
            <th></th>
            {duplicar === 'A'? 
              <th>
                <FormControl>
                  <Button label="DUPLICAR" onPress={handleSubmit(handleAddDuplicate)} />                 
                </FormControl>
              </th>:''}            
          </tr>
        </tfoot>
      </table>
    </Page>
  )
}
